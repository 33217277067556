import { createSelector } from 'reselect';

import { PitchesModuleFilterDataType } from 'components/Pitches/data';
import { IConfirmPopupProps } from 'components/Popups/ConfirmPopup/ConfirmPopup';
import { SearchDataType, SearchItemsNums } from 'components/Popups/DesktopSearch/data';
import { ISystemPitch } from 'components/Popups/SystemPitchForm';
import { SongsModuleFilterDataType } from 'components/Songs/data';
import { IFavoritesData, IFilterByPlaylist, IPlaylistMedia, ISortByPlaylist } from 'containers/Sidebar/data';

import { IAsset } from 'services/Api';
import { IReduxState } from 'store';
import IGeneralState, {
  ConfirmPopupTypes,
  IActiveEditingUser,
  IActiveTrack,
  IAddPopupConfigState,
  IAdminControls,
  IDefaultTeam,
  IDownloadPopupConfig,
  IEditPlaylistConfig,
  IError,
  IHoldRequest,
  IHoldRequestActionConfig,
  IHoldRequestFormConfig,
  IHoldRequestRejectConfig,
  INotificationsConfig,
  IPitchDetails,
  IPitchDetailsDeletePopupConfig,
  IPitchesModule,
  IPitchesModuleConfirmPopupConfig,
  IPitchesModuleExpirationConfig,
  IPlaylistFilter,
  IRecentEditedSong,
  IShareConfig,
  ISongDetails,
  ISongDetailsDeletePopupConfig,
  ISongsModule,
  ITeam,
  IUser,
  IUserData,
  IUserProfile,
  PitchContentType,
  PlayingStateTypes,
  PlaylistHashActionTypes,
  ShareItemTypes,
  SongsModuleMyFilterType,
  UsageKeys,
} from 'store/reducers/general/types';
import { additionalRoutes, getFilteredRoutes, permissions, routes, UserRoleCodes } from 'utils';

import {
  AdminControlsFilterDataType,
  HoldRequestTabKeys,
  HoldRequestTabValuesType,
  ICurrentTrack,
  StatusTabValuesType,
} from 'types';

// main
export const getStore = (state: IReduxState): IReduxState => state;
export const getGeneral = (state: IReduxState): IGeneralState => getStore(state).general;

// general
export const getActiveNavItemId = (state: IReduxState): number => getGeneral(state).activeNavItemId;

// popups
export const getMainConfirmPopup = (state: IReduxState): IConfirmPopupProps => getGeneral(state).mainConfirmPopup;
export const getMainNotification = (state: IReduxState): string => getGeneral(state).mainNotification;

// isOpen
export const getLoading = (state: IReduxState): boolean => getGeneral(state).loading;
export const getIsOpenSidebar = (state: IReduxState): boolean => getGeneral(state).isOpenSidebar;
export const getIsOpenTopMenu = (state: IReduxState): boolean => getGeneral(state).isOpenTopMenu;
export const getIsOpenSearch = (state: IReduxState): boolean => getGeneral(state).isOpenSearch;
export const getIsOpenMobileMockup = (state: IReduxState): boolean => getGeneral(state).isOpenMobileMockup;
export const getIsExpandedPlaybar = (state: IReduxState): boolean => getGeneral(state).isExpandedPlaybar;
export const getIsOpenShowHideColumns = (state: IReduxState): boolean => getGeneral(state).isOpenShowHideColumns;
export const getIsMarkersVisible = (state: IReduxState): boolean => getGeneral(state).isMarkersVisible;

// search
export const getSearchData = (state: IReduxState): SearchDataType => getGeneral(state).searchData;
export const getSearchItemsNumState = (state: IReduxState): SearchItemsNums => getGeneral(state).searchItemsNum;

// user
export const getUserProfile = (state: IReduxState): IUserProfile => getGeneral(state).userProfile;
export const getUserInfo = (state: IReduxState): IUser => getUserProfile(state).user;
export const getUserStatus = (state: IReduxState): string => getUserInfo(state).status;
export const getUserTeams = (state: IReduxState): ITeam[] => getUserProfile(state).teams;
export const getUserTeamsForAutocomplete = createSelector([getUserTeams], (userTeams) =>
  userTeams.map((item) => ({ id: item.id, title: item.name }))
);
export const getUserDefaultTeam = (state: IReduxState): IDefaultTeam | null => getUserInfo(state).defaultTeam;
export const getUserTeamName = createSelector([getUserDefaultTeam], (defaultTeam) => defaultTeam?.name);
export const getUserTeamId = (state: IReduxState): string | undefined => getUserDefaultTeam(state)?.id;
export const getUserTerritory = (state: IReduxState): string | null => getUserInfo(state).defaultTerritory;
export const getUserId = (state: IReduxState): string => getUserInfo(state).id;
export const getUserRole = (state: IReduxState): string => getUserInfo(state).role;
export const getUserIsTeamAdmin = (state: IReduxState): boolean => getUserInfo(state).isTeamAdmin;
export const getUserTeamIds = createSelector([getUserTeams, getUserTeamId], (teams, userTeamId) => [
  ...teams.reduce((total, item) => [...total, ...(item?.isViewing ? [item.id] : [])], [] as string[]),
  ...(userTeamId && !teams.some((el) => el.isViewing && el.id === userTeamId) ? [userTeamId] : []),
]);
export const getUserPermissions = createSelector(
  [getUserRole],
  (role) => permissions[(role as UserRoleCodes) || UserRoleCodes.SuperAdmin]
);
export const getUserFullName = createSelector(
  [getUserInfo],
  (userInfo) => `${userInfo.firstName} ${userInfo.lastName}`
);

//routes
export const getAllRoutes = createSelector([getUserPermissions], (currentPermissions) => {
  const allRoutes = [...additionalRoutes, ...routes];

  return getFilteredRoutes(allRoutes, currentPermissions);
});
export const getRoutes = createSelector([getUserPermissions], (currentPermissions) =>
  getFilteredRoutes(routes, currentPermissions)
);
export const getAdditionalRoutes = createSelector([getUserPermissions], (currentPermissions) =>
  getFilteredRoutes(additionalRoutes, currentPermissions)
);
export const getActiveRoute = createSelector([getActiveNavItemId, getAllRoutes], (activeRouteId, allRoutes) =>
  allRoutes.find((route) => route.id === activeRouteId)
);

// admin controls
export const getAdminControls = (state: IReduxState): IAdminControls => getGeneral(state).adminControls;
export const getAdminControlsIsOpenFiltersPopup = (state: IReduxState): boolean =>
  getAdminControls(state).isOpenFiltersPopup;
export const getAdminControlsIsOpenEditPopup = (state: IReduxState): boolean => getAdminControls(state).isOpenEditPopup;
export const getAdminControlsFiltersData = (state: IReduxState): Partial<AdminControlsFilterDataType> =>
  getAdminControls(state).filtersData;
export const getAdminControlsActiveEditingUser = (state: IReduxState): Partial<IActiveEditingUser> | null =>
  getAdminControls(state).activeEditingUser;
export const getAdminControlsActiveEditingUserData = (state: IReduxState): IUserData | undefined =>
  getAdminControlsActiveEditingUser(state)?.data;
export const getAdminControlsActiveEditingUserRowId = (state: IReduxState): string | undefined =>
  getAdminControlsActiveEditingUser(state)?.rowId;
export const getAdminControlsConfirmPopupType = (state: IReduxState): ConfirmPopupTypes | '' =>
  getAdminControls(state).openConfirmPopupType;
export const getAdminControlsStatusTabValues = (state: IReduxState): StatusTabValuesType =>
  getAdminControls(state).statusTabValues;

// songs module
export const getSongsModule = (state: IReduxState): ISongsModule => getGeneral(state).songsModule;
export const getSongsModuleFiltersData = (state: IReduxState): Partial<SongsModuleFilterDataType> =>
  getSongsModule(state).filtersData;
export const getSongsModuleIsOpenFiltersPopup = (state: IReduxState): boolean =>
  getSongsModule(state).isOpenFiltersPopup;
export const getSongsModuleSongsCount = (state: IReduxState): number | undefined => getSongsModule(state).songsCount;
export const getSongsModuleLocalAssets = (state: IReduxState): IAsset[] => getSongsModule(state).localAssets;
export const getSongsModuleMyFiltersData = (state: IReduxState): Partial<SongsModuleMyFilterType> =>
  getSongsModule(state).myFilter;

// song details
export const getSongDetails = (state: IReduxState): ISongDetails => getGeneral(state).songDetails;
export const getSongDetailsDeletePopupConfig = (state: IReduxState): ISongDetailsDeletePopupConfig =>
  getSongDetails(state).deletePopupConfig;
export const getSongDetailsAddPopupConfig = (state: IReduxState): IAddPopupConfigState =>
  getSongDetails(state).addPopupConfig;

// pitches
export const getPitchesModule = (state: IReduxState): IPitchesModule => getGeneral(state).pitchesModule;
export const getPitchesModuleFiltersData = (state: IReduxState): Partial<PitchesModuleFilterDataType> =>
  getPitchesModule(state).filtersData;
export const getPitchesModuleMyFilters = (state: IReduxState): Partial<SongsModuleMyFilterType> =>
  getPitchesModule(state).myFilter;
export const getPitchesModuleIsOpenFiltersPopup = (state: IReduxState): boolean =>
  getPitchesModule(state).isOpenFiltersPopup;
export const getPitchesModuleConfirmPopupConfig = (state: IReduxState): IPitchesModuleConfirmPopupConfig =>
  getPitchesModule(state).confirmPopupConfig;
export const getPitchesModuleExpirationConfig = (state: IReduxState): IPitchesModuleExpirationConfig =>
  getPitchesModule(state).expirationConfig;
export const getPitchesModuleReloadHash = (state: IReduxState): string | undefined =>
  getPitchesModule(state).reloadHash;
export const getPitchesModuleIsOpenExpirationPopup = (state: IReduxState): boolean =>
  getPitchesModuleExpirationConfig(state).isOpenExpirationPopup;
export const getPitchesModuleExpirationDate = (state: IReduxState): string =>
  getPitchesModuleExpirationConfig(state).expirationDate;
export const getPitchesModuleExpirationClickedId = (state: IReduxState): string =>
  getPitchesModuleExpirationConfig(state).id;
export const getEditingPitchRowId = (state: IReduxState): string => getPitchesModule(state).confirmPopupConfig.rowId;
export const getSystemPitchForm = (state: IReduxState): ISystemPitch | undefined =>
  getPitchesModule(state).systemPitchForm;
export const getSystemPitchContentType = (state: IReduxState): PitchContentType | undefined =>
  getPitchesModule(state).pitchContentType;

// pitch details
export const getPitchDetails = (state: IReduxState): IPitchDetails => getGeneral(state).pitchDetails;
export const getPitchDetailsDeletePopupConfig = (state: IReduxState): IPitchDetailsDeletePopupConfig =>
  getPitchDetails(state).deletePopupConfig;

// playlist
export const getFavoritesList = (state: IReduxState): IPlaylistMedia[] => getGeneral(state).favoritesList;
export const getFavoritesData = (state: IReduxState): IFavoritesData => getGeneral(state).favoritesData;
export const getFavoritePlaylistId = (state: IReduxState): string => getFavoritesData(state).id;
export const getFavoritesIdsObj = (state: IReduxState): { [key: string]: boolean } => getGeneral(state).favoritesIdsObj;
export const getPlaylistFilter = (state: IReduxState): IPlaylistFilter => getGeneral(state).playlistFilter;
export const getPlaylistFilterField = (state: IReduxState): IFilterByPlaylist => getPlaylistFilter(state).filterField;
export const getPlaylistSortField = (state: IReduxState): ISortByPlaylist => getPlaylistFilter(state).sort;
export const getPlaylistQueryField = (state: IReduxState): string => getPlaylistFilter(state).query;
export const getChangedPlaylistId = (state: IReduxState): string => getGeneral(state).changedPlaylistId;
export const getPlaylistHash = (state: IReduxState): number => getGeneral(state).playlistHash.hash;
export const getPlaylistHashAction = (state: IReduxState): PlaylistHashActionTypes =>
  getGeneral(state).playlistHash.action;
export const getCurrentPlaylistId = (state: IReduxState): string => getGeneral(state).currentPlaylistId;
export const getRecentPlayedList = (state: IReduxState): IPlaylistMedia[] => getGeneral(state).recentPlayedList;
export const getEditPlaylistConfig = (state: IReduxState): IEditPlaylistConfig => getGeneral(state).editPlaylist;

// featured playlist

export const getFeaturedPlaylistHash = (state: IReduxState): number => getGeneral(state).featuredPlaylistHash;

// playbar
export const getCurrentTracks = (state: IReduxState): ICurrentTrack[] => getGeneral(state).currentTracks;
export const getActiveTrack = (state: IReduxState): IActiveTrack => getGeneral(state).activeTrack;
export const getPlaybarPlayingState = (state: IReduxState): PlayingStateTypes => getGeneral(state).playbarPlayingState;
export const getActiveTrackSongId = (state: IReduxState): string => getActiveTrack(state).songId;
export const getActiveTrackMediaId = (state: IReduxState): string => getActiveTrack(state).mediaId;
export const getActiveTrackIndex = (state: IReduxState): number => getActiveTrack(state).index;
export const getActiveTrackIsHaveMarkers = (state: IReduxState): boolean | undefined =>
  getActiveTrack(state).isHaveMarkers;

// error
export const getError = (state: IReduxState): IError => getGeneral(state).error;
export const getErrorTitle = (state: IReduxState): string => getError(state).title;
export const getErrorText = (state: IReduxState): string => getError(state).text;
export const getIsOpenError = (state: IReduxState): boolean => getError(state).isOpen;
export const getErrorContainerId = (state: IReduxState): string => getError(state).containerId || '';

// share
export const getShareConfig = (state: IReduxState): IShareConfig => getGeneral(state).shareConfig;
export const getShareItemType = (state: IReduxState): ShareItemTypes | '' => getShareConfig(state).type;
export const getIsOpenSharePopup = (state: IReduxState): boolean => getShareConfig(state).isOpen;
export const getSharedSongId = (state: IReduxState): string => getShareConfig(state).songId;
export const getSharedPlaylistId = (state: IReduxState): string => getShareConfig(state).playlistId;

// hold request
export const getHoldRequest = (state: IReduxState): IHoldRequest => getGeneral(state).holdRequest;
export const getHoldRequestFormPopupConfig = (state: IReduxState): IHoldRequestFormConfig => getHoldRequest(state).form;
export const getHoldRequestActionPopupConfig = (state: IReduxState): IHoldRequestActionConfig =>
  getHoldRequest(state).action;
export const getHoldRequestRejectPopupConfig = (state: IReduxState): IHoldRequestRejectConfig =>
  getHoldRequest(state).reject;
export const getHoldRequestActiveTab = (state: IReduxState): HoldRequestTabKeys | undefined | null =>
  getHoldRequest(state).activeTab;
export const getHoldRequestStatusTabValues = (state: IReduxState): HoldRequestTabValuesType =>
  getHoldRequest(state).statusTabValues;
export const getHoldRequestReloadHash = (state: IReduxState): string | undefined => getHoldRequest(state).reloadHash;

// download popup
export const getDownloadPopupConfig = (state: IReduxState): IDownloadPopupConfig =>
  getGeneral(state).downloadPopupConfig;

// recent
export const getRecentEditedSongs = (state: IReduxState): IRecentEditedSong[] =>
  getGeneral(state).recentEditedSongs.songs;
export const getRecentEditedSongsUsages = (state: IReduxState): UsageKeys[] =>
  getGeneral(state).recentEditedSongs.usages;

// notifications
export const getNotificationsConfig = (state: IReduxState): INotificationsConfig =>
  getGeneral(state).notificationsConfig;
export const getNotificationsCount = (state: IReduxState): number => getNotificationsConfig(state).count;
