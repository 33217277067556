import { useState } from 'react';

import { Button, EditTooltip, PageHeader } from 'components/Reusable';
import { ButtonType } from 'components/Reusable/Button';
import { TextField } from 'components/UI';

import { useDidUpdate, useMemoSelector } from 'hooks';
import { getUserPermissions } from 'store';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import styles from './Contacts.module.scss';

import { IContactGroup } from '.';

export interface IGroupDetailsHeaderProps {
  details: IContactGroup;
  onAddContactsClick: () => void;
  onTitleChange: (value: string) => void;
}

const GroupDetailsHeader = ({ details, onAddContactsClick, onTitleChange }: IGroupDetailsHeaderProps): JSX.Element => {
  const permissions = useMemoSelector(getUserPermissions);

  const [title, setTitle] = useState<string>(details.title || '');

  useDidUpdate(() => setTitle(details.title || ''), [details.title]);

  return (
    <>
      <Button type={ButtonType.GoBack} />
      <div className={styles.contactsHeader}>
        <PageHeader className={styles.pageHeader} smallMargin />
        {permissions.contactActions && (
          <Button Icon={<PlusCircleIcon />} onClick={onAddContactsClick} text="Add Contacts" />
        )}
      </div>
      <EditTooltip
        disableEditing={!permissions.contactActions}
        className={styles.titleEditTooltip}
        Component={<TextField name="title" onChange={(v) => setTitle(v as string)} value={title} />}
        onOpen={() => setTitle(details.title || '')}
        onSubmit={() => title && onTitleChange(title)}
      >
        <h2 className={styles.title}>{details.title || '-'}</h2>
      </EditTooltip>
    </>
  );
};

export default GroupDetailsHeader;
