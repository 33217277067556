import { useMemo } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import classNames from 'classnames';

interface ICircularDeterminateProps {
  value: number | null;
  showText?: boolean;
  showNullAsDash?: boolean;
}

interface IStyleProps {
  wrapperWidth: number | 'unset';
}

const CircularProgressSpinner = ({ value, showText = false, showNullAsDash = false }: ICircularDeterminateProps) => {
  const classes = useStyles({ wrapperWidth: showText ? 'unset' : 22 });

  const isNumber = useMemo(() => typeof value === 'number' || !showNullAsDash, [showNullAsDash, value]);

  return (
    <div className={classes.root}>
      {showText && <span>{isNumber ? `${Number(value)}%` : '-'}</span>}
      {isNumber && (
        <>
          <CircularProgress
            classes={{
              root: classNames(classes.progressRoot, classes.mainProgressRoot),
              circle: classes.circle,
              svg: classes.circleSvg,
            }}
            variant="determinate"
            value={Number(value)}
          />
          <CircularProgress
            classes={{
              root: classNames(classes.progressRoot, classes.backgroundProgressRoot),
              circle: classes.circle,
              svg: classes.circleSvg,
            }}
            variant="determinate"
            value={100}
          />
        </>
      )}
    </div>
  );
};

export default CircularProgressSpinner;

const useStyles = makeStyles<Theme, IStyleProps>((theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      position: 'relative',
      width: (props) => props.wrapperWidth,
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
      '& > span': {
        color: '#383838',
        fontFamily: 'SuisseIntl-Regular-M, sans-serif',
        fontSize: '13px',
      },
    },
    progressRoot: {
      height: '22px !important',
      width: '22px !important',
      marginLeft: 0,
      padding: '2px',
    },
    mainProgressRoot: {
      color: '#D9A81B',
    },
    backgroundProgressRoot: {
      color: '#E5E5E5',
      position: 'absolute',
      right: 0,
      zIndex: -1,
    },
    circleSvg: {
      overflow: 'visible',
    },
    circle: {
      strokeWidth: '8px',
    },
  })
);
