import classNames from 'classnames';

import { songStatuses } from 'components/Songs/data';
import { Tooltip } from 'components/UI';

import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongStatuses.module.scss';

import { ISongStatuses } from 'types';

const getStatusShowingTitle = (status: string) => {
  return songStatuses.find((item) => item.addCode === status)?.title || status;
};

const SongStatuses = ({ statuses }: { statuses: ISongStatuses[] }) => {
  return (
    <>
      <Tooltip text={`${getStatusShowingTitle(statuses[0].status)} (${statuses[0].artist})`}>
        <div className={classNames(styles.statusCard, globalStyles.textEllipsis)}>
          {`${getStatusShowingTitle(statuses[0].status)}`}
        </div>
      </Tooltip>
      {statuses.length > 1 && (
        <Tooltip
          text={statuses
            .map((item) => `${getStatusShowingTitle(item.status)} (${item.artist})`)
            .slice(1)
            .join(', ')}
        >
          <span className={styles.statusAdditionalCard}>+{statuses.length - 1}</span>
        </Tooltip>
      )}
    </>
  );
};

export default SongStatuses;
