import { Controller, useForm } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Button, Dialog, DoubleBtnGroup } from 'components/Reusable';
import { NewAutocomplete as Autocomplete } from 'components/UI';

import { useDidUpdate, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getUserTeamIds } from 'store';
import { DEFAULT_ERROR_CONFIG } from 'utils';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import styles from './Contacts.module.scss';

import { IAutocompleteOption } from 'types';

export interface IAddToGroupFormItem {
  contacts: IAutocompleteOption[];
  group?: IAutocompleteOption;
}

export interface IAddToGroupFormProps {
  item?: IAddToGroupFormItem;
  onClose?: () => void;
  onNewGroupClick?: () => void;
  onSubmit?: (groupWasCreated: boolean) => void;
}

const AddToGroupForm = ({ item, onClose, onNewGroupClick, onSubmit }: IAddToGroupFormProps) => {
  const teamIds = useMemoSelector(getUserTeamIds);
  const methods = useForm<IAddToGroupFormItem>({ defaultValues: item, mode: 'all' });

  const submitForm = async (values: IAddToGroupFormItem) => {
    const v = { ...values };
    if (v.group && !v.group.id) {
      const group = await Api.addGroup({ title: v.group.title });
      v.group.id = group?.id || '';
    }
    const contactIds = v.contacts.map(({ id }) => id.toString());
    await Api.addContactsToGroup(v.group?.id.toString() || '', contactIds, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    onSubmit && onSubmit(!values.group?.id);
  };

  useDidUpdate(
    () => methods.setValue('group', item?.group, { shouldTouch: true, shouldValidate: true }),
    [item?.group]
  );

  return (
    <Dialog classes={{ paper: styles.formPaper }} onClose={onClose} open>
      <DialogTitle className={styles.formTitle}>Add Contacts to Group</DialogTitle>
      <DialogContent className={styles.formContent}>
        <Controller
          control={methods.control}
          name="group"
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              {...field}
              className={styles.control}
              disabled={methods.formState.isSubmitting}
              error={error?.message}
              highlightValid
              label="Select Group"
              onResolveSuggestions={(text) =>
                Api.getGroups(
                  { filter: { name: text || '', teamIds }, skip: 0, take: 10 },
                  { errorPopupConfig: DEFAULT_ERROR_CONFIG }
                ).then((items) => items?.map((i) => ({ id: i.id || '', title: i.title || '' })) || [])
              }
              required
            />
          )}
          rules={{ required: true }}
        />
        <Button Icon={<PlusCircleIcon />} onClick={onNewGroupClick} text="New Group" />
      </DialogContent>
      <DialogActions className={styles.formActions}>
        <DoubleBtnGroup
          disabled={!methods.formState.isValid || !methods.formState.isDirty}
          isPending={methods.formState.isSubmitting}
          name1="Cancel"
          name2="Add Contacts"
          onClick1={onClose}
          onClick2={methods.handleSubmit(submitForm)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddToGroupForm;
