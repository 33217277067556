import { SongsModuleFilterKeys } from 'components/Songs/data';

import { capitalizeOnlyFirstChar, convertCategoriesToText } from 'utils';

import { ReactComponent as DeleteIcon } from 'assets/delete.svg';

export interface ITagsListProps<T, R> {
  data: T;
  dataTitles: R;
  onDeleteTag: (tagKey: keyof T) => void;
  tagsContainerClass?: string;
  tagClass?: string;
  onlyFirstCharCapitalizedItems?: Array<keyof T>;
  title?: string;
}

const TagsList = <T, R>({
  data,
  dataTitles,
  onDeleteTag,
  tagsContainerClass,
  tagClass,
  onlyFirstCharCapitalizedItems,
  title,
}: ITagsListProps<T, R>): JSX.Element => {
  return (
    <div className={tagsContainerClass}>
      {title && <h2>{title}</h2>}
      {Object.keys(data).map((tagKey) => {
        const key = tagKey as keyof T;
        const item = data[key];
        const isObjectItem = item && typeof item === 'object' && !Array.isArray(item);
        const tag = isObjectItem ? [item] : item;

        const isValue = (typeof tag === 'string' || Array.isArray(tag)) && tag.length;

        if (!isValue) return null;

        const onlyFirstCharCapitalized = onlyFirstCharCapitalizedItems?.includes(key);

        const valueText = `${
          Array.isArray(tag)
            ? convertCategoriesToText(
                tag as unknown as { title: string; name: string }[],
                onlyFirstCharCapitalized,
                tagKey === SongsModuleFilterKeys.writers ? ' /' : ','
              )
            : onlyFirstCharCapitalized
            ? capitalizeOnlyFirstChar(tag)
            : tag
        }`;

        return (
          <div className={tagClass} key={tagKey}>
            <span>{dataTitles[tagKey as keyof R]}:</span>
            {valueText}
            <DeleteIcon onClick={() => onDeleteTag(tagKey as keyof T)} />
          </div>
        );
      })}
    </div>
  );
};

export default TagsList;
