import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Button, Spinner } from 'components/Reusable';
import { ButtonType, IButtonMenuItemProps, IButtonProps as IClassicButtonProps } from 'components/Reusable/Button';
import { RowNodeExt } from 'components/UI/DataGrid';

import { useIsMounted } from 'hooks';

import styles from './ActionsCellRenderer.module.scss';

import { ISpinnerProps } from '../Spinner';

export type { IButtonMenuItemProps };

export interface IButtonProps extends IClassicButtonProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => void | Promise<void>;
}

export interface IActionsCellRendererProps {
  buttons?: (IButtonProps | false | undefined)[];
  spinnerHeight?: ISpinnerProps['height'];
  spinnerWidth?: ISpinnerProps['width'];
  visible?: boolean;
  isTabletSize?: boolean;
  node?: RowNodeExt;
}

const ActionsCellRenderer = ({
  buttons,
  spinnerHeight = 15,
  spinnerWidth = 15,
  visible,
  isTabletSize,
  node,
}: IActionsCellRendererProps): JSX.Element => {
  const isMounted = useIsMounted();
  const [loadingIndex, setLoadingIndex] = useState<number>();

  useEffect(() => {
    if (!isTabletSize || !node?.expanded) return;
    node?.setDetailsRow(false);
  }, [isTabletSize, node]);

  return (
    <div className={classNames(styles.actionsWrapper, 'actions-renderer-wrapper')}>
      <div className={classNames(styles.actions, 'actions-renderer', { [styles.visibleActions]: visible })}>
        {buttons?.map((b, i) => {
          if (!b) return null;

          const loading = loadingIndex === i;
          const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement, MouseEvent>) => {
            e.stopPropagation();
            if (loading) return;
            (async () => {
              setLoadingIndex(i);
              b.onClick && (await b.onClick(e));
              if (isMounted()) setLoadingIndex(undefined);
            })();
          };

          return (
            <Button
              className={styles.button}
              key={i}
              menuItems={b.menuItems}
              onClick={onClick}
              tooltip={b.tooltip}
              type={b.type || ButtonType.Text}
            >
              {loading ? <Spinner height={spinnerHeight} width={spinnerWidth} /> : b.text}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default ActionsCellRenderer;
