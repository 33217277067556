import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AutoSizer, Index, IndexRange, InfiniteLoader, List, ListRowProps } from 'react-virtualized';
import classNames from 'classnames';

import { Button } from 'components/Reusable';
import { ButtonType } from 'components/Reusable/Button';

import Api from 'services/Api';
import { setCurrentPlaylistId, setNotificationsConfig } from 'store';
import {
  defaultConfigLoadItems,
  getUTCTimeFromNow,
  isDev,
  NOTIFICATIONS_PAGE_SIZE,
  NOTIFICATIONS_PAGE_SIZE_IN_POPUP,
  Paths,
} from 'utils';

import { ReactComponent as MarkReadIcon } from 'assets/mark_read.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as SettingsIcon } from 'assets/settings.svg';
import { ReactComponent as TrashIcon } from 'assets/trash-2.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './NotificationsList.module.scss';

import DoubleIconBtnGroup from '../DoubleIconBtnGroup';
import { IBENotification, NotificationsFilterTypes, NotificationStatuses, NotificationTypes } from './data';
import { IListConfig } from 'types';

interface INotificationsListProps {
  isInPopup?: boolean;
  handleClose?: () => void;
}

const NotificationsList = ({ isInPopup, handleClose }: INotificationsListProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeFilterType, setActiveFilterType] = useState(NotificationsFilterTypes.all);
  const [notifications, setNotifications] = useState<IBENotification[]>([]);
  const [listConfig, setListConfig] = useState<IListConfig>({ total: 0, hasNextPage: true });
  const [loading, setLoading] = useState<{ [key: string]: boolean }>({});

  const isUnreadTab = useMemo(() => activeFilterType === NotificationsFilterTypes.unread, [activeFilterType]);

  const getNotifications = useCallback(
    async (startIndex: number, needReset?: boolean) => {
      if ((!listConfig.hasNextPage && !needReset) || (isInPopup && !needReset)) {
        return Promise.resolve([]);
      }

      const res = await Api.getNotifications(
        isInPopup
          ? {
              skip: 0,
              take: NOTIFICATIONS_PAGE_SIZE_IN_POPUP,
              ...(isUnreadTab && { status: NotificationStatuses.new }),
            }
          : { skip: startIndex, take: NOTIFICATIONS_PAGE_SIZE }
      );

      if (!res) return;

      if (isInPopup && res.notifications.length && res.notifications[0].status === NotificationStatuses.new) {
        dispatch(setNotificationsConfig({ count: 1 }));
      }

      setNotifications((prev) => {
        const newNotifications = [...(needReset ? [] : prev), ...res.notifications];

        setListConfig({
          total: res?.totalCount || 0,
          hasNextPage: !isInPopup && newNotifications.length < res?.totalCount,
        });
        return newNotifications;
      });

      return res;
    },
    [dispatch, isInPopup, isUnreadTab, listConfig.hasNextPage]
  );

  const renderNotificationText = (notification: IBENotification) => {
    const messageArr = notification.message.split(/\{(-?\d+)\}/g).map((item: string) => {
      const displayName = item && notification.refMetas.find((refMeta) => refMeta.refKey === `{${item}}`)?.displayName;

      return displayName ? <span className={globalStyles.f13h16SuisseSB_grayDarker}>{displayName}</span> : item;
    });

    return (
      <span className={classNames(globalStyles.f13h16SuisseREG_grayDarker, styles.messageText)}>{messageArr}</span>
    );
  };

  const updateNotificationStatus = async (notificationId: string, status: NotificationStatuses) => {
    if (loading[notificationId]) return;

    setLoading((prev) => ({ ...prev, [notificationId]: true }));

    const res = await Api.updateNotificationsStatuses({ notificationIds: [notificationId], status });

    if (!res) return;

    setNotifications((prev) => {
      if (status === NotificationStatuses.deleted || (status === NotificationStatuses.read && isUnreadTab)) {
        return prev.filter((item) => item.id !== notificationId);
      }

      return prev.map((item) => ({ ...item, ...(item.id === notificationId && { status }) }));
    });
    setLoading((prev) => ({ ...prev, [notificationId]: false }));

    Number.isInteger(res.newNotificationCount) && dispatch(setNotificationsConfig({ count: res.newNotificationCount }));
  };

  const getNotificationMenuItems = (notification: IBENotification) => {
    return [
      notification.status === NotificationStatuses.new
        ? { onClick: () => updateNotificationStatus(notification.id, NotificationStatuses.read), text: 'Mark as Read' }
        : {
            onClick: () => updateNotificationStatus(notification.id, NotificationStatuses.new),
            text: 'Mark as Unread',
          },
      {
        onClick: () => updateNotificationStatus(notification.id, NotificationStatuses.deleted),
        text: 'Remove Notification',
      },
    ];
  };

  const isRowLoaded = ({ index }: Index) => {
    return !!notifications[index];
  };

  const loadMoreRows = ({ startIndex }: IndexRange, needReset?: boolean) => {
    return getNotifications(startIndex, needReset)
      .then((res) => res)
      .catch(() => null);
  };

  const onClickNotification = (notification: IBENotification) => {
    let isClickedWorked = true;

    switch (notification.type) {
      case NotificationTypes.inAppApprovalStatusChanged:
        history.push(Paths.myAccount);
        break;
      case NotificationTypes.inAppSongAddedToPlaylist:
      case NotificationTypes.inAppSongRemovedFromPlaylist:
      case NotificationTypes.inAppSongRemovedFromPlaylistRemaining: {
        const playlistId = notification.refMetas[2]?.id;
        playlistId && dispatch(setCurrentPlaylistId(playlistId));
        isClickedWorked = !!playlistId;
        break;
      }
      case NotificationTypes.inAppSongRemovedFromPlaylistDeactivated: {
        const playlistId = notification.refMetas[0]?.id;
        playlistId && dispatch(setCurrentPlaylistId(playlistId));
        isClickedWorked = !!playlistId;
        break;
      }
      case NotificationTypes.inAppHoldOnStatusCreated:
      case NotificationTypes.inAppHoldOffStatusCreated:
      case NotificationTypes.inAppCutStatusCreated:
      case NotificationTypes.inAppUnreleasedCutStatusCreated:
      case NotificationTypes.inAppSongShared: {
        const songId = notification.refMetas[1]?.id;
        songId && history.push(`${Paths.songs}/${songId}`);
        isClickedWorked = !!songId;
        break;
      }
      case NotificationTypes.inAppPitchViewed: {
        const pitchId = notification.refMetas[0]?.id;
        pitchId && history.push(`${Paths.pitches}/${pitchId}`);
        isClickedWorked = !!pitchId;
        break;
      }
      case NotificationTypes.inAppHoldRequestCreated:
      case NotificationTypes.inAppHoldRequestApproveReminder:
      case NotificationTypes.inAppUnAnsweredHoldRequestReminder: {
        const holdId = notification.refMetas[2]?.id;
        holdId && history.push(`${Paths.admin}?holdId=${holdId}#tab=holdRequest`);
        isClickedWorked = !!holdId;
        break;
      }
      case NotificationTypes.inAppSearchAlert: {
        const alertId = notification.refMetas[0]?.id;
        alertId && history.push(`${Paths.search}/${alertId}`);
        isClickedWorked = !!alertId;
        break;
      }
      default:
        isClickedWorked = false;
        break;
    }

    if (!isClickedWorked) return;

    handleClose && handleClose();

    if (notification.status === NotificationStatuses.new) {
      updateNotificationStatus(notification.id, NotificationStatuses.read);
    }
  };

  function rowRenderer({ key, index, style }: ListRowProps) {
    const notification = notifications[index];
    const isUnread = notification?.status === NotificationStatuses.new;

    return notification ? (
      <div key={key} className={styles.notificationRow} style={style} onClick={() => onClickNotification(notification)}>
        <div className={classNames(styles.dot, { [styles.hidden]: !isUnread })} />
        <div className={styles.textContainer}>
          {renderNotificationText(notification)}
          <span className={globalStyles.f11h13SuisseREG_gray}>{getUTCTimeFromNow(notification.date)}</span>
        </div>
        {isInPopup ? (
          <Button
            type={ButtonType.Text}
            text={<MoreIcon className={styles.moreIcon} />}
            menuItems={getNotificationMenuItems(notification)}
          />
        ) : (
          <div className={styles.actions}>
            <Button
              type={ButtonType.Text}
              onClick={() =>
                updateNotificationStatus(
                  notification.id,
                  isUnread ? NotificationStatuses.read : NotificationStatuses.new
                )
              }
              disabled={loading[notification.id]}
            >
              {isUnread ? 'Mark as Read' : 'Mark as Unread'}
            </Button>
            <Button
              type={ButtonType.Text}
              Icon={<TrashIcon />}
              onClick={() => updateNotificationStatus(notification.id, NotificationStatuses.deleted)}
              disabled={loading[notification.id]}
            />
          </div>
        )}
      </div>
    ) : null;
  }

  const currentListId = `notifications-list${isInPopup ? '-isInPopup' : ''}`;
  const remoteRowCount = notifications.length + 1;

  const resetScroll = () => {
    const listElement = document.getElementById(currentListId);

    listElement && listElement.scrollTo({ top: 0 });
  };

  const resetList = (skipScroll?: boolean) => {
    !skipScroll && resetScroll();

    loadMoreRows(defaultConfigLoadItems, true);
  };

  const onClickMarkAllAsRead = async () => {
    const res = await Api.updateNotificationsStatuses({ status: NotificationStatuses.read });

    if (!res) return;

    Number.isInteger(res.newNotificationCount) && dispatch(setNotificationsConfig({ count: res.newNotificationCount }));
    resetList();
  };

  const onClickNotificationsSettings = () => {
    history.push(Paths.notificationPreferences);

    handleClose && handleClose();
  };

  useEffect(() => {
    if (!isInPopup) return;
    resetList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilterType]);

  return (
    <div
      className={classNames(styles.notificationsContainer, {
        [styles.inPopup]: isInPopup,
        [styles.inPage]: !isInPopup,
      })}
    >
      <div className={styles.notificationsHeader}>
        <span className={globalStyles.f20h24SuisseSB_black}>Notifications</span>
        <DoubleIconBtnGroup
          hideButton1={!isDev}
          className={styles.btnsContainer}
          Icon1={MarkReadIcon}
          Icon2={SettingsIcon}
          spacing={isInPopup ? 15 : 20}
          textSpacing={12}
          name1={isInPopup ? '' : 'Mark all as Read'}
          name2={isInPopup ? '' : 'Notification Settings'}
          namesClassName={globalStyles.f13h16SuisseREG_grayDarker}
          tooltip1={isInPopup ? 'Mark all as Read' : ''}
          tooltip2={isInPopup ? 'Notification Settings' : ''}
          onClick1={onClickMarkAllAsRead}
          onClick2={onClickNotificationsSettings}
        />
      </div>
      {isInPopup && (
        <div className={styles.notificationsFilter}>
          {Object.values(NotificationsFilterTypes).map((item) => (
            <span
              key={item}
              className={classNames(globalStyles.f13h16SuisseSB_grayDark, {
                [styles.activeNotificationFilterType]: item === activeFilterType,
              })}
              onClick={() => setActiveFilterType(item)}
            >
              {item}
            </span>
          ))}
        </div>
      )}
      <div className={styles.notificationsListItemsContainer}>
        <InfiniteLoader isRowLoaded={isRowLoaded} loadMoreRows={loadMoreRows} rowCount={remoteRowCount}>
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <List
                  id={currentListId}
                  width={width}
                  height={height}
                  onRowsRendered={onRowsRendered}
                  ref={registerChild}
                  rowCount={listConfig.hasNextPage ? remoteRowCount : notifications.length}
                  rowHeight={isInPopup ? 70 : 55}
                  rowRenderer={rowRenderer}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </div>
    </div>
  );
};

export default NotificationsList;
