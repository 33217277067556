import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ConfirmPopup, NotificationPopup, ValidationErrorPopup } from 'components/Popups';
import { PageHeader } from 'components/Reusable';
import { SongsDropzone } from 'components/Songs';
import DataGrid, { DefaultColDefConfigs } from 'components/UI/DataGrid';
import AddPopupWrapper from 'containers/AddPopupWrapper';

import { useDidUpdate, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import {
  getIsOpenSidebar,
  getPlaylistHash,
  getPlaylistHashAction,
  getRecentEditedSongs,
  getSongDetails,
  getSongDetailsAddPopupConfig,
  getSongsModuleLocalAssets,
  getUserPermissions,
  setCurrentPlaylistId,
  setLoading,
  setRecentEditedSongs,
  setSongDetails,
  setSongsModule,
} from 'store';
import { PlaylistHashActionTypes, UsageKeys } from 'store/reducers/general/types';
import {
  DEFAULT_ERROR_CONFIG,
  DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG,
  findRecentEditedSongById,
  getDataGridItems,
  Paths,
  SCREEN_BREAKPOINTS,
} from 'utils';

import { ReactComponent as ArrowBackIcon } from 'assets/arrow-back.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './SongDetails.module.scss';

import { SongDetailsActions, SongHeader, SongNotes, SongTags } from './';
import { playlistColumns, RemoveActionTypes, statusColumns, teamPitchesColumns, versionsColumns } from './data';
import { mapAssetToSongMedia } from './utils';
import {
  AddPopupTypes,
  ISong,
  ISongMedias,
  ISongNote,
  ISongStatuses,
  ISongTag,
  SongStatusTypes,
  SongTagsTypes,
} from 'types';

const getProcessingIds = (songs: ISongMedias[]): string[] => {
  return songs.reduce((result: string[], song) => {
    if (typeof song.processing === 'number' && song.id) {
      result.push(song.id);
    }
    return result;
  }, []);
};

const useProcessWatching = (
  processingIds: string[],
  grid: React.MutableRefObject<GridApi | null>,
  setProcessingIds: React.Dispatch<React.SetStateAction<string[]>>
) => {
  useEffect(() => {
    let mounted = true;
    const unmountCallback = () => {
      mounted = false;
    };
    if (processingIds.length > 0) {
      setTimeout(async () => {
        const assets = (await Api.getAssetsByIds(processingIds)) || [];
        const songs = assets.map(mapAssetToSongMedia).filter((s) => s.id && processingIds.indexOf(s.id) > -1);
        if (!mounted) return unmountCallback;
        grid.current?.forEachNode((node) => {
          const data = node.data as ISongMedias;
          if (typeof data.processing === 'number' && data.id && processingIds.indexOf(data.id) > -1) {
            const freshData = songs.find((s) => s.id === data.id);
            if (freshData && (freshData.error !== data.error || freshData.processing !== data.processing)) {
              typeof freshData.processing === 'number' && freshData.error === data.error
                ? node.setDataValue('processing', freshData.processing) // Refresh only one cell (no row blink)
                : node.setData(freshData); // Flash the whole row (blink is visible)
            }
          }
        });
        setProcessingIds(grid.current ? getProcessingIds(getDataGridItems(grid.current)) : []);
      }, 5000);
    }
    return unmountCallback;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processingIds]);
};

const SongDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const {
    isOpenSidebar,
    localAssets,
    songDetails,
    permissions,
    addPopupConfig,
    playlistHash,
    playlistHashAction,
    recentEditedSongs,
  } = useMemoSelector((state) => ({
    isOpenSidebar: getIsOpenSidebar(state),
    localAssets: getSongsModuleLocalAssets(state).filter((a) => a.workId === id),
    songDetails: getSongDetails(state),
    addPopupConfig: getSongDetailsAddPopupConfig(state),
    permissions: getUserPermissions(state),
    playlistHash: getPlaylistHash(state),
    playlistHashAction: getPlaylistHashAction(state),
    recentEditedSongs: getRecentEditedSongs(state),
  }));

  const { deletePopupConfig, validationErrorPopupType, versionsLoading, reloadHash: detailsReloadHash } = songDetails;

  const [song, setSong] = useState<ISong>();
  const [processingIds, setProcessingIds] = useState<string[]>([]);
  const [reloadHash, setReloadHash] = useState(new Date().toISOString());
  const [notificationPopupConfig, setNotificationPopupConfig] = useState({ isOpen: false, text: '' });

  const versionsGridRef = useRef<GridApi | null>(null);

  const updateSongDetails = useCallback(
    async (closeLoading = false, update?: Partial<ISong>) => {
      const [songDetails, drafts] = await Promise.all([
        update ? { ...update } : Api.getSongDetails({ pathId: id, errorPopupConfig: DEFAULT_ERROR_CONFIG }),
        permissions.viewSongsTabs
          ? Api.getMyAssets({ skip: 0, take: 200, workId: id }, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
          : null,
      ]);

      if (!songDetails) {
        dispatch(setLoading(false));
        return;
      }

      setSong((prev) => {
        const newSong = update ? { ...prev, ...songDetails } : songDetails;

        if (newSong?.medias) {
          // Note: preventing the duplication of drafts when `update` existing
          const filteredDrafts = drafts?.filter((draft) => {
            const existingDraftIndex = newSong.medias.findIndex((media: ISongMedias) => media.id === draft.id);
            const isExistingDraft = existingDraftIndex !== -1;

            if (isExistingDraft) {
              newSong.medias[existingDraftIndex] = mapAssetToSongMedia(draft);
            }

            return !isExistingDraft;
          });

          newSong.medias = [
            ...newSong?.medias?.map((el: ISongMedias) => ({
              ...el,
              writers: newSong?.writers || [],
              songTitle: newSong?.title,
              workCode: newSong?.workCode || '',
            })),
            ...(filteredDrafts && Array.isArray(filteredDrafts) ? filteredDrafts.map(mapAssetToSongMedia) : []),
          ];
        }

        // Note: this is fixing latency, later we should remove and use getSong request when it will be fixed in BE side
        if (!newSong?.medias?.length) {
          newSong.labelView = false;
        }

        return newSong;
      });

      setReloadHash(new Date().toISOString());
      versionsLoading && dispatch(setSongDetails({ versionsLoading: false }));
      closeLoading && dispatch(setLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, detailsReloadHash]
  );

  useEffect(() => {
    if (playlistHashAction === PlaylistHashActionTypes.songDetails) return;
    updateSongDetails(true);
  }, [playlistHash, playlistHashAction, updateSongDetails]);

  useEffect(() => {
    if (!song) return;

    const recentEditedSong = findRecentEditedSongById(recentEditedSongs, song.id);

    if (!recentEditedSongs.length || !recentEditedSong) return;

    updateSongDetails(true, { ...(recentEditedSong.update as unknown as ISong) });

    dispatch(setRecentEditedSongs({ usages: [UsageKeys.songDetails] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recentEditedSongs]);

  useEffect(() => {
    dispatch(setLoading(true));
    updateSongDetails(true);
  }, [dispatch, updateSongDetails]);

  // Put new drafts from upload popup to grid
  useDidUpdate(() => {
    if (localAssets?.length > 0) {
      const newDrafts = localAssets.filter((d) => !song?.medias.find(({ id }) => id === d.id)).map(mapAssetToSongMedia);
      versionsGridRef.current?.applyTransaction({ add: newDrafts });
      setProcessingIds((prev) => [...prev, ...getProcessingIds(newDrafts)]);
      dispatch(setSongsModule({ localAssets: [] }));
    }
  }, [localAssets]);

  useProcessWatching(processingIds, versionsGridRef, setProcessingIds);

  const onClickDelete = async () => {
    let res;

    if (!song?.id) return;

    const params = {
      pathId: deletePopupConfig.id,
      errorPopupConfig: DEFAULT_ERROR_CONFIG,
    };

    const update: Partial<ISong> = {};

    const version =
      deletePopupConfig.type === RemoveActionTypes.version && versionsGridRef.current
        ? getDataGridItems<ISongMedias>(versionsGridRef.current).find((m) => m.id === params.pathId)
        : undefined;

    switch (deletePopupConfig.type) {
      case RemoveActionTypes.version:
        res = version?.draft
          ? await Api.bulkDeleteAssets([params.pathId], { errorPopupConfig: DEFAULT_ERROR_CONFIG }).then(() => true)
          : await Api.removeSongMedia(song.id, params);

        if (res && !version?.draft) {
          const filteredMedias = song.medias.filter((media) => media.id !== params.pathId);
          const hasSavedVersion = filteredMedias.some((el) => !el?.draft);
          update.medias = filteredMedias;

          if (!hasSavedVersion) {
            update.createdByName = '';
          }
        }
        break;
      case RemoveActionTypes.tag: {
        const foundTag = song.tags.find((el) => el.id === deletePopupConfig.id);
        if (!foundTag || !foundTag.id) return;

        const tagsBody =
          foundTag.type === SongTagsTypes.genre.toLowerCase()
            ? [
                foundTag.id,
                ...song.tags.reduce((total, item) => {
                  return [...total, ...(item.type === foundTag.tag ? [item.id as string] : [])];
                }, [] as string[]),
              ]
            : [foundTag.id];

        res = await Api.removeSongTags(song.id, tagsBody, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

        if (res) {
          update.tags = song.tags.filter((tag) => !tagsBody.includes(tag.id || ''));
        }
        break;
      }
      case RemoveActionTypes.status:
        res = await Api.removeSongStatus(song.id, params);

        if (res) {
          update.statuses = song.statuses.filter((status) => status.id !== params.pathId);
        }
        break;
      case RemoveActionTypes.note:
        res = await Api.removeSongNote(song.id, params);

        if (res) {
          update.notes = song.notes.filter((note) => note.id !== params.pathId);
        }
        break;
      default:
        break;
    }

    dispatch(setSongDetails({ deletePopupConfig: DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG }));

    if (!res) return;

    version?.draft
      ? versionsGridRef.current?.applyTransaction({ remove: [version] })
      : await updateSongDetails(false, update);

    setNotificationPopupConfig({ isOpen: true, text: deletePopupConfig.notificationText });
  };

  const closeAddPopup = () => {
    dispatch(setSongDetails({ addPopupConfig: { ...addPopupConfig, isOpen: false } }));
  };

  const onClickAdd = (res: unknown) => {
    const { type } = addPopupConfig;

    setNotificationPopupConfig({
      isOpen: true,
      text: `${addPopupConfig.type} ${addPopupConfig.isEdit ? 'Updated' : 'Added'}`,
    });

    updateSongDetails(false, {
      ...(type === AddPopupTypes.status &&
        addPopupConfig.isEdit && {
          statuses: song?.statuses.map((item) =>
            item.id === (res as ISongStatuses).id ? (res as ISongStatuses) : item
          ),
        }),
      ...(type === AddPopupTypes.status &&
        !addPopupConfig.isEdit && {
          statuses: res as ISongStatuses[],
        }),
      ...(type === AddPopupTypes.tags && {
        tags: res as ISongTag[],
      }),
      ...(type === AddPopupTypes.notes && {
        notes: res as ISongNote[],
      }),
    });

    closeAddPopup();
  };

  const onClickBack = () => {
    history.goBack();
  };

  const onDragStartGridParent = (e: React.DragEvent) => {
    const t = e.target as HTMLElement;
    if (!t.getAttribute) return;
    if (t.getAttribute('role') !== 'row') return;

    const selectedSongData = versionsGridRef.current?.getRowNode(t.getAttribute('row-id') as string)?.data;

    e.dataTransfer?.setData(
      'text',
      JSON.stringify({
        ...selectedSongData,
        id,
        mediaId: selectedSongData.id,
        mediaPath: selectedSongData.path,
        writers: song?.writers.map((item) => item.name),
        labelView: song?.labelView,
      })
    );
  };

  const openPlaylistById = (id: string) => {
    window.innerWidth < SCREEN_BREAKPOINTS.desktopSmall && history.push(Paths.playlists);
    dispatch(setCurrentPlaylistId(id));
  };

  const onMouseDownGridParent = (e: React.MouseEvent) => {
    const t = e.target as HTMLElement;
    const rowEl = t.closest('div.ag-row[role="row"]');
    if (rowEl === null) return;

    const selectedSongData = versionsGridRef.current?.getRowNode(rowEl.getAttribute('row-id') as string)?.data;

    if (!selectedSongData?.id || selectedSongData.status === SongStatusTypes.ARCHIVED) return;

    if (!rowEl.getAttribute || !rowEl.classList.contains('ag-row-level-0')) return;
    if (rowEl.getAttribute('draggable') === 'true') return;
    rowEl.setAttribute('draggable', 'true');
  };

  useEffect(() => {
    if (!processingIds.length) {
      versionsGridRef?.current?.refreshHeader();
    }
  }, [processingIds]);

  return (
    <div
      className={classNames(styles.songDetails, globalStyles.wrapper, {
        [styles.songDetailsOpenSidebar]: isOpenSidebar,
      })}
    >
      <ArrowBackIcon onClick={onClickBack} className={styles.arrowBackIcon} />
      <AddPopupWrapper onCancel={closeAddPopup} subtitle={song?.title} onAdd={onClickAdd} />
      <ConfirmPopup
        isOpen={deletePopupConfig.isOpen}
        setIsOpen={() => dispatch(setSongDetails({ deletePopupConfig: DEFAULT_SONG_DETAILS_DELETE_POPUP_CONFIG }))}
        questionText={deletePopupConfig.questionText}
        mainText={deletePopupConfig.mainText}
        btnDoneText="Delete"
        onClickSubmit={onClickDelete}
      />
      <NotificationPopup
        isOpen={notificationPopupConfig.isOpen}
        text={notificationPopupConfig.text}
        setIsOpen={() => setNotificationPopupConfig({ ...notificationPopupConfig, isOpen: false })}
      />
      <ValidationErrorPopup
        description="Please enter values for the highlighted fields and try again."
        isOpen={validationErrorPopupType === 'required-field'}
        onClose={() => dispatch(setSongDetails({ validationErrorPopupType: undefined }))}
        title="Required Field Missing"
      />
      <ValidationErrorPopup
        description="Please select unique version for song and try again."
        isOpen={validationErrorPopupType === 'versions-not-unique'}
        onClose={() => dispatch(setSongDetails({ validationErrorPopupType: undefined }))}
        title="Versions are not unique"
      />
      <PageHeader smallMargin title="SONG DETAILS" Component={<SongDetailsActions song={song} />} />
      {song ? (
        <>
          <SongHeader song={song} updateSong={updateSongDetails} />
          {permissions.viewSongDetailsTags && <SongTags tags={song.tags} />}
          {permissions.viewSongDetailsVersions && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionTitle}>Versions</div>
              <div
                style={{ display: 'contents' }}
                onDragStart={onDragStartGridParent}
                onMouseDown={onMouseDownGridParent}
              >
                <DataGrid
                  ref={versionsGridRef}
                  columns={versionsColumns}
                  datasource={{
                    rowData: versionsLoading ? [] : song.medias,
                    type: 'classic',
                  }}
                  defaultColDef={DefaultColDefConfigs}
                  disableColumnsConfig={true}
                  loading={versionsLoading}
                  rowHeight={54}
                  reloadHash={reloadHash}
                  getGridHeight={() => '170px'}
                />
              </div>
              {permissions.songDetailsActions && <SongsDropzone workId={id} />}
            </div>
          )}
          {permissions.viewSongDetailsStatuses && (
            <div className={styles.sectionContainer}>
              <div className={styles.sectionTitle}>Status</div>
              <DataGrid
                columns={statusColumns}
                datasource={{
                  rowData: song.statuses || [],
                  type: 'classic',
                }}
                defaultColDef={DefaultColDefConfigs}
                disableColumnsConfig={true}
                rowHeight={54}
                reloadHash={reloadHash}
                getGridHeight={() => '170px'}
              />
            </div>
          )}
          {permissions.songDetailsActions && (
            <>
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Team Pitches</div>
                <DataGrid
                  columns={teamPitchesColumns}
                  datasource={{
                    rowData: song.pitches || [],
                    type: 'classic',
                  }}
                  defaultColDef={DefaultColDefConfigs}
                  disableColumnsConfig={true}
                  rowHeight={54}
                  reloadHash={reloadHash}
                  getGridHeight={() => '170px'}
                />
              </div>
              <div className={styles.sectionContainer}>
                <div className={styles.sectionTitle}>Playlist</div>
                <DataGrid
                  columns={playlistColumns}
                  datasource={{
                    rowData: song.playlists || [],
                    type: 'classic',
                  }}
                  onRowDoubleClicked={({ data }) => openPlaylistById(data.id)}
                  defaultColDef={DefaultColDefConfigs}
                  disableColumnsConfig={true}
                  rowHeight={54}
                  reloadHash={reloadHash}
                  getGridHeight={() => '170px'}
                />
              </div>
              <SongNotes
                notes={song.notes}
                updateSongDetails={updateSongDetails}
                setNotificationPopupConfig={setNotificationPopupConfig}
              />
            </>
          )}
        </>
      ) : null}
    </div>
  );
};

export default SongDetails;
