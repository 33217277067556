import { useMemo, useRef, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { ConfirmPopup, NotificationPopup } from 'components/Popups';
import {
  ActionsCellRenderer,
  DateCellRenderer,
  NoDataAvailable,
  SelectionPanel,
  TableFilters,
  TooltipCellRenderer,
} from 'components/Reusable';
import DataGrid, { CellRendererProps, DataGridColDef, GetRowsParams } from 'components/UI/DataGrid';

import { useContainerWidth, useDidUpdate, useMemoSelector, useMount } from 'hooks';
import Api from 'services/Api';
import { getUserPermissions, getUserTeamIds } from 'store';
import { DEFAULT_ERROR_CONFIG, IColumnConfig, TABLE_PAGE_SIZE, UserPreferenceTypes } from 'utils';

import { ReactComponent as ChevronDownIcon } from 'assets/arrow.svg';
import { ReactComponent as MoreIcon } from 'assets/more.svg';
import { ReactComponent as DeleteIcon } from 'assets/remove.svg';
import dataGridStyles from 'styles/modules/DataGridTheme.module.scss';
import styles from './Contacts.module.scss';

import { ContactsFilterPopup, ExpandedRow, IContact } from '.';
import { IContactsFilterItem } from './ContactsFilterPopup';

export interface IContactsGridProps {
  groupId?: string;
  onAddToGroupClick?: (items: IContact[]) => void;
  onEditContactClick: (contact: IContact) => void;
  reloadHash: string | undefined;
  userId?: string;
}

const isTabletSize = (width: number): boolean => {
  return width < 977;
};

const getColumns = ({
  onDelete,
  width = 0,
  hideActions,
}: {
  onDelete: (contact: IContact) => void;
  width: number | undefined;
  hideActions: boolean;
}): DataGridColDef<IContact>[] => [
  ...(!hideActions
    ? [
        {
          checkboxSelection: true,
          field: '',
          headerCheckboxSelection: true,
          suppressColumnsToolPanel: true,
          width: 60,
        },
      ]
    : []),
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'name',
    flex: 2,
    headerName: 'Name',
    suppressColumnsToolPanel: true,
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'organizationName',
    flex: 1.6,
    headerName: 'Organization',
    hide: isTabletSize(width),
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'email',
    flex: 2.4,
    headerName: 'Email',
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'team',
    flex: 1.5,
    headerName: 'Team',
    hide: isTabletSize(width),
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'createdBy',
    flex: 1.5,
    headerName: 'Created By',
    hide: isTabletSize(width),
  },
  {
    cellRendererFramework: DateCellRenderer,
    field: 'createdOn',
    flex: 1.3,
    headerName: 'Created On',
    hide: isTabletSize(width),
  },
  {
    cellClass: 'overflow-visible',
    cellRendererFramework: ({ data, node }) => (
      <ActionsCellRenderer
        node={node}
        isTabletSize={isTabletSize(width)}
        buttons={
          isTabletSize(width)
            ? [
                {
                  onClick: () => {
                    const expanded = node.expanded;

                    node.setExpanded(!expanded);
                    node.setMaster(!expanded);
                  },
                  text: <ChevronDownIcon className={classNames({ [styles.chevronUp]: node.expanded })} />,
                },
                ...(!hideActions
                  ? [
                      {
                        menuItems: [{ onClick: () => data && onDelete(data), text: 'Delete' }],
                        text: <MoreIcon className={styles.moreIcon} />,
                      },
                    ]
                  : []),
              ]
            : [
                ...(!hideActions
                  ? [{ onClick: () => data && onDelete(data), text: <DeleteIcon />, tooltip: 'Delete' }]
                  : []),
              ]
        }
        visible={isTabletSize(width)}
      />
    ),
    field: 'actions',
    pinned: 'right',
    headerName: 'Actions',
    minWidth: 94,
    suppressColumnsToolPanel: true,
  },
];

const ContactsGrid = (props: IContactsGridProps) => {
  const { teamIds, permissions } = useMemoSelector((state) => ({
    teamIds: getUserTeamIds(state),
    permissions: getUserPermissions(state),
  }));

  const [containerRef, width] = useContainerWidth<HTMLDivElement>();
  const [delData, setDelData] = useState<IContact[]>();
  const [filters, setFilters] = useState<{ showDialog?: boolean; values: IContactsFilterItem }>({ values: {} });
  const [notification, setNotification] = useState<string>();
  const [reloadHash, setReloadHash] = useState(props.reloadHash);
  const [selectedItems, setSelectedItems] = useState<IContact[]>([]);
  const [columnsFromBackend, setColumnsFromBackend] = useState<DataGridColDef<IContact>[]>([]);

  const gridRef = useRef<GridApi>(null);

  const columns = useMemo(() => {
    const defaultColumns = getColumns({
      onDelete: (data) => setDelData([data]),
      width,
      hideActions: !permissions.contactActions,
    });

    return !isTabletSize(width) && columnsFromBackend.length
      ? [...columnsFromBackend.slice(0, columnsFromBackend.length - 1), defaultColumns[defaultColumns.length - 1]]
      : defaultColumns;
  }, [permissions.contactActions, width, columnsFromBackend]);

  const getRows = ({ startRow }: GetRowsParams) =>
    props.groupId
      ? Api.getGroupContacts(
          { id: props.groupId, skip: startRow, take: TABLE_PAGE_SIZE },
          { errorPopupConfig: DEFAULT_ERROR_CONFIG }
        )
      : Api.getContacts(
          {
            filter: { email: filters.values.email, name: filters.values.name, teamIds, userId: props.userId },
            skip: startRow,
            take: TABLE_PAGE_SIZE,
          },
          { errorPopupConfig: DEFAULT_ERROR_CONFIG }
        );

  const onDeleteConfirmed = async () => {
    if (delData && delData.length > 0) {
      const contactIds = delData.map(({ id }) => id || '');
      await (props.groupId
        ? Api.deleteContactsFromGroup(props.groupId, contactIds, { errorPopupConfig: DEFAULT_ERROR_CONFIG })
        : Promise.all(contactIds.map((id) => Api.deleteContact(id, { errorPopupConfig: DEFAULT_ERROR_CONFIG }))));
      setDelData(undefined);
      setNotification('Contact Deleted');
      setReloadHash(new Date().toISOString());
    }
  };

  useDidUpdate(() => setReloadHash(props.reloadHash), [props.reloadHash]);

  useMount(async () => {
    try {
      const preferences = await Api.getUserPreferencesByPreferenceName({ pathId: UserPreferenceTypes.contacts });
      const preference = JSON.parse(preferences?.preference);

      if (Array.isArray(preference?.columns)) {
        const newColumns = columns.map((item) => {
          const isExistingFieldVisible = preference?.columns.some(
            (elem: IColumnConfig) => elem.field === item.field && elem.hide
          );

          return { ...item, hide: isExistingFieldVisible };
        });

        setColumnsFromBackend(newColumns);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err);
    }
  });

  const onChangeColumnsConfig = (columns: DataGridColDef<IContact>[]) => {
    Api.createUpdateUserPreferences(
      { columns: columns.map((item) => ({ field: item.field, hide: !!item.hide })) },
      { pathId: UserPreferenceTypes.contacts }
    );
    setColumnsFromBackend(columns);
  };

  return (
    <>
      {!props.groupId && (
        <TableFilters
          data={filters.values}
          dataTitles={{ email: 'Email', name: 'Name' }}
          isOpenFiltersPopup={false}
          onClickFilterIcon={() => setFilters((prev) => ({ ...prev, showDialog: true }))}
          onDeleteTag={(tagKey) => {
            const values = { ...filters.values };
            delete values[tagKey];
            setFilters((prev) => ({ ...prev, values }));
            setReloadHash(new Date().toISOString());
          }}
        />
      )}
      <div
        className={classNames(dataGridStyles.dataGridWithSelectionPanel, {
          [styles.dataGridFullHeight]: Boolean(props.groupId),
          [styles.dataGridHeightWithFilters]: !props.groupId,
        })}
        ref={containerRef}
      >
        {width && (
          <>
            <SelectionPanel
              buttons={[
                props.onAddToGroupClick && {
                  onClick: (_, items) => props.onAddToGroupClick && props.onAddToGroupClick(items),
                  text: 'Add to Group',
                },
                {
                  onClick: (nodes) => setDelData(nodes.map(({ data }) => data)),
                  text: 'Delete',
                },
              ]}
              gridRef={gridRef}
              selectedItems={selectedItems}
            />
            <DataGrid
              className={classNames(dataGridStyles.dataGrid, styles.dataGridFullHeight)}
              columns={columns}
              datasource={{ getRows, pageSize: TABLE_PAGE_SIZE, type: 'infinite' }}
              defaultColDef={{ suppressMovable: true }}
              disableColumnsConfig={isTabletSize(width)}
              fullWidthCellRendererFramework={(p: CellRendererProps<DataGridColDef<IContact>>) => (
                <ExpandedRow {...p} />
              )}
              gridId="contacts"
              isFullWidthCell={(p) => p.level === 1}
              noRowsOverlayComponentFramework={() => <NoDataAvailable />}
              onRowClicked={(e) => {
                if (!permissions.contactActions) return;
                props.onEditContactClick(e.data);
              }}
              onSelectionChanged={setSelectedItems}
              ref={gridRef}
              reloadHash={reloadHash}
              rowHeight={54}
              rowSelection="multiple"
              onChangeColumnsConfig={onChangeColumnsConfig}
              suppressRowClickSelection
            />
            <ConfirmPopup
              btnDoneText="Delete"
              isOpen={(delData || []).length > 0}
              mainText={
                delData?.length === 1
                  ? 'This contact will be deleted and you cannot undo this action.'
                  : 'These contacts will be deleted and you cannot undo this action.'
              }
              onClickSubmit={onDeleteConfirmed}
              questionText={delData?.length === 1 ? `Delete ${delData[0].name}?` : 'Delete selected items?'}
              setIsOpen={() => setDelData(undefined)}
            />
            <NotificationPopup
              isOpen={Boolean(notification)}
              setIsOpen={() => setNotification(undefined)}
              text={notification || ''}
            />
          </>
        )}
      </div>
      <ContactsFilterPopup
        filters={filters.values}
        onClose={() => setFilters((prev) => ({ ...prev, showDialog: false }))}
        onSubmit={(values) => {
          setFilters({ showDialog: false, values });
          setReloadHash(new Date().toISOString());
        }}
        open={Boolean(filters.showDialog)}
      />
    </>
  );
};

export default ContactsGrid;
