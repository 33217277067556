/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './ColConfig.module.scss';

import { DataGridColDef } from './IDataGrid';
import { capitalize, getThemeClass, persistColumnsSettings } from './utils';

export interface ColConfigProps<T> {
  /** Grid instance unique ID, used for persisting user manageable settings */
  gridId?: string;
  columns: DataGridColDef<T>[];
  theme?: 'wcm' | 'material' | string | string[];

  onChange: (columns: DataGridColDef<T>[]) => void;
}

const ColConfig = <T,>(props: ColConfigProps<T>) => {
  const [opened, setOpened] = useState<boolean>(false);

  const toggleHandler = useCallback(() => setOpened((o) => !o), []);

  const themeClass = useMemo(() => getThemeClass(props.theme), [props.theme]);

  const onChangeHandler = useCallback(
    (columns: DataGridColDef<T>[]) => {
      props.gridId && persistColumnsSettings<T>(props.gridId, columns);
      props.onChange(columns);
    },
    [props]
  );

  if (props.columns.filter((c) => !c.suppressColumnsToolPanel).length === 0) return null;

  return (
    <div className={`${themeClass} ${styles.container}`}>
      <div className={`settings ${styles.settings} ${opened ? 'opened ' + styles.opened : ''}`} onClick={toggleHandler}>
        <span className="ag-icon ag-icon-columns" />
        {opened && <ColPanel columns={props.columns} onChange={onChangeHandler} onClose={() => setOpened(false)} />}
      </div>
    </div>
  );
};

const ColPanel = <T,>(props: Pick<ColConfigProps<T>, 'columns' | 'onChange'> & { onClose: () => void }) => {
  useEffect(() => {
    document.body.addEventListener('click', props.onClose);
    return () => document.body.removeEventListener('click', props.onClose);
  }, [props.onClose]);

  const onChangeClosure = useCallback(
    (c: DataGridColDef<T>) => () =>
      props.onChange(
        props.columns.map((cc) => {
          if (cc.field !== c.field) return cc;
          return { ...cc, hide: !c.hide };
        })
      ),
    [props]
  );

  return (
    <div className={`panel ${styles.panel}`} onClick={(e) => e.stopPropagation()}>
      {/* Intentionally not localized yet */}
      <h2 className={`title ${styles.title}`}>Show/Hide Columns</h2>
      <ul className={`list ${styles.list}`}>
        {props.columns
          .filter((c) => !c.suppressColumnsToolPanel)
          .map((c, i) => {
            return (
              <li key={i} className={`item ${styles.item}`} onClick={onChangeClosure(c)}>
                <div
                  className={`ag-wrapper ag-checkbox-input-wrapper ${!c.hide ? 'ag-checked' : ''} ${styles.checkbox}`}
                >
                  <input className="ag-checkbox-input" type="checkbox" readOnly={true} checked={!c.hide} />
                </div>
                <div className={styles.name}>{c.headerName || capitalize(c.field)}</div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ColConfig;
