import { useCallback, useEffect, useRef, useState } from 'react';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import PlayBar from 'components/PlayBar';
import DataGrid, { DataGridColDef, DefaultColDefConfigs, GetRowsHandler } from 'components/UI/DataGrid';
import { autoSizeActionsColumn } from 'components/UI/DataGrid/utils';

import { useMemoSelector, useWindowSize } from 'hooks';
import { DownloadPublicTypes } from 'services/Api';
import { getIsExpandedPlaybar } from 'store';
import { SCREEN_BREAKPOINTS, TABLE_PAGE_SIZE } from 'utils';

import { ReactComponent as ExpiredExternalIcon } from 'assets/expired_external.svg';
import { ReactComponent as WCMLogoIcon } from 'assets/wcm_logo.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './ExternalPage.module.scss';

import {
  ExternalDataErrorMessagesByStatus,
  ExternalDataStatuses,
  ExternalPageTitleActions,
  ExternalTypes,
  getExternalColumns,
  IExternalData,
  IExternalMedia,
} from './data';

interface IExternalPageProps {
  code: string;
  type: ExternalTypes;
  data?: IExternalData;
  addPlayActivity?: () => void;
}

const ExternalPage = ({ data, addPlayActivity, type, code }: IExternalPageProps) => {
  const isExpandedPlaybar = useMemoSelector(getIsExpandedPlaybar);
  const { width: windowWidth } = useWindowSize();
  const gridRef = useRef<GridApi | null>(null);

  const [columns, setColumns] = useState<DataGridColDef<IExternalMedia>[]>([]);

  const getRowsHandler: GetRowsHandler<IExternalMedia> = useCallback(
    async () =>
      data?.medias.map((el) => ({
        ...el,
        dataCode: code,
        downloadType: ExternalTypes.pitch === type ? DownloadPublicTypes.pitch : DownloadPublicTypes.playlist,
        dataId: data?.id || '',
        dataTitle: data?.title || '',
        ...(data?.contacts && { contactId: data?.contacts[0]?.id || '' }),
      })) || [],
    [data?.medias, data?.id, data?.title, data?.contacts, code, type]
  );

  useEffect(() => {
    if (!data) return;
    const isMobile = windowWidth < SCREEN_BREAKPOINTS.tablet;

    setColumns(getExternalColumns(data.isDownloadable || type === ExternalTypes.share, isMobile));
    setTimeout(() => autoSizeActionsColumn(gridRef.current), 100);
  }, [data, type, windowWidth]);

  return (
    <div className={classNames(styles.externalWrapper, { [styles.externalWrapperExpandedPlaybar]: isExpandedPlaybar })}>
      <div className={styles.externalContainer}>
        <div className={styles.externalHeader}>
          <WCMLogoIcon />
        </div>
        <div className={styles.titleContainer}>
          <span className={classNames(globalStyles.f22h22PlaakB_white, styles.pitchTitle)}>{data?.title}</span>
          <span className={styles.externalCount}>{data?.medias?.length || 0}</span>
          <span className={classNames(globalStyles.f13h22PlaakB_white, styles.byInfo)}>
            {`${ExternalPageTitleActions[type]} by`} {data?.createdByName}
          </span>
        </div>
        {data && (
          <>
            {[ExternalDataStatuses.expired, ExternalDataStatuses.deactivated, ExternalDataStatuses.error].includes(
              data.status
            ) ? (
              <div className={styles.expiredInfoContainer}>
                <ExpiredExternalIcon />
                <span className={globalStyles.f13h16SuisseSB_white}>
                  {ExternalDataErrorMessagesByStatus[data.status]}
                </span>
              </div>
            ) : (
              <DataGrid
                ref={gridRef}
                rowClass={styles.row}
                className={styles.dataGrid}
                columns={columns}
                datasource={{
                  type: 'infinite',
                  pageSize: TABLE_PAGE_SIZE,
                  getRows: getRowsHandler,
                  total: data?.medias?.length || 0,
                }}
                defaultColDef={DefaultColDefConfigs}
                disableColumnsConfig={true}
                rowHeight={54}
              />
            )}
          </>
        )}
      </div>
      <PlayBar isDark isExternal addPlayActivity={addPlayActivity} />
    </div>
  );
};

export default ExternalPage;
