import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './SearchQuery.module.scss';

interface ISearchProps {
  placeholder?: string;
  maxLength?: number;
  value: string;
  onChange: (value: string) => Promise<void> | void;
}

const SearchQuery = ({ placeholder, maxLength = 80, onChange, value }: ISearchProps): JSX.Element => {
  return (
    <div className={styles.search}>
      <SearchIcon />
      <input
        maxLength={maxLength}
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default SearchQuery;
