import { NotificationPlatformTypes } from 'components/NotificationPreferences/data';
import { AudioInclusionTypes } from 'components/Popups/DesktopSearch/data';
import { NotificationStatuses } from 'components/Reusable/NotificationsList/data';

import { IError } from 'store/reducers/general/types';
import { UserRoleCodes } from 'utils';

// Note: Should be 'types/index' for not having circular dependency
import { AdminControlsFilterDataType, HoldRequestTabKeys, SongVersion } from 'types/index';

export enum UserStatusActions {
  restore = 'restore',
  reject = 'reject',
  archive = 'archive',
  approve = 'approve',
  setupUserDefault = 'setup-user-default',
  resend = 'resend',
}

export type IRequestMethods = 'DELETE' | 'GET' | 'PUT' | 'POST' | 'PATCH';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HandlerType = (details: any, status?: number) => void | Promise<void>;

export interface IMethods {
  delete: 'DELETE';
  get: 'GET';
  put: 'PUT';
  post: 'POST';
  patch: 'PATCH';
}

export interface IAPIOptions {
  method?: IRequestMethods;
  path?: string;
  body?: { [key: string]: unknown } | unknown[] | File;
  params?: IRequestParams;
  query?: string;
}
export interface IRequestParams {
  pathId?: string;
  url?: string;
  getWithBlob?: boolean;
  getWithText?: boolean;
  withoutApi?: boolean;
  withoutResponseData?: boolean;
  withoutToken?: boolean;
  withoutStringifyBody?: boolean;
  skipJsonResIfError?: boolean;
  fileType?: string;
  isThrow?: boolean;
  handler?: HandlerType;
  errorPopupConfig?: IError;
  externalHeaders?: { [key: string]: unknown };
  isNotificationsIntervalCall?: boolean;
}

export interface IPagination {
  skip: number;
  take: number;
  sorting?: {
    field: string;
    direction: string;
  };
}

interface IGetUsersFilters extends Omit<AdminControlsFilterDataType, 'defaultTeam'> {
  defaultTeamId: string;
}

export interface IGetUsers {
  userFilter: IGetUsersFilters;
  pagination: IPagination;
}

export interface IChangeUserStatus {
  path?: UserStatusActions;
  body?: {
    id?: string;
    email?: string;
    role?: string;
    defaultTeamId?: string | number;
  };
}

interface IGetSongsFilters {
  title: string;
  workCode: string;
  writers: string[];
  workStatuses: string[];
  teams: string[];
  wcm: string;
  uploadedBy: string;
  modifiedBy: string;
  createdOn: {
    start: string;
    end: string;
  };
  uploadedOn: {
    start: string;
    end: string;
  };
  modifiedOn: {
    start: string;
    end: string;
  };
}

export interface IGetSongs {
  pagination: IPagination;
  searchQuery?: string;
  artist?: string;
  audioInclusionType?: AudioInclusionTypes;
  onHold?: boolean;
  offHold?: boolean;
  cut?: boolean;
  unreleasedCut?: boolean;
  genres?: {
    name: string;
    subGenres: string[];
  }[];
  moods?: string[];
  vocals?: string[];
  languages?: string[];
  territory?: string;
  bpmRange?: {
    start: number;
    end: number;
  };
  filter?: IGetSongsFilters;
}

export interface ISearchAlertSendToType {
  userIds?: string[];
  teamIds?: string[];
  contactIds?: string[];
}

export interface ISearchAlert extends ISearchAlertSendToType {
  name: string;
  query: string;
}

export enum AssetStatus {
  Error = 'FAILED',
  OK = 'COMPLETED',
  Processing = 'UPLOADED_TO_S3',
  Uploaded = 'UPLOADED',
}

export interface IAsset {
  duration?: number | null;
  fileName?: string | null;
  id: string;
  status?: AssetStatus;
  title?: string | null;
  uploadedBy?: string | null;
  uploadedOn?: string | null;
  waveForm?: string | null;
  workId?: string | null;
}

export interface ICreatePlaylistBody {
  title: string;
  mediaIds: string[];
  keywords: string[] | null;
}

export interface IGetPlaylists {
  query?: string;
  pagination: {
    skip: number;
    take: number;
    sort?: {
      fieldName: string;
      direction: string;
    };
  };
  filter: {
    userId: string;
    filterType?: string;
    teamIds?: string[];
  };
}

export interface IMarkerBody {
  mediaId: string;
  markerTime: number;
  title: string;
}

export interface IAddWorkItemTag {
  subgenre?: string;
  tag: string;
  type: string;
}

export interface IAddWorkItem {
  assets?: {
    assetId?: string;
    title?: string;
    version?: SongVersion;
  }[];
  bpm?: number;
  tags?: IAddWorkItemTag[];
  title?: string;
  wcm?: number;
  writers?: string[];
  labelView?: boolean;
}

export interface IAddWorkResponse {
  createdBy: string;
  createdOn: string;
  id: string;
  title: string;
  updatedBy: string;
  updatedOn: string;
  workCode: string;
}

export interface IWorkHoldRequestBody {
  artist: string | null;
  label: string | null;
  contact: string | null;
  message: string;
}

export interface IUpdateSongDetailsBody {
  title?: string;
  bpm?: number;
  writers?: string[];
  wcm?: number;
}

export interface IAddSongStatusBody {
  status: string;
  artist: string;
  label: string;
  contact: string;
  producer: string;
  extContact: string;
  subStatus: string;
  date: string;
  released: boolean;
}

export interface IAddSongTagBody {
  type: string;
  tag: string;
  subgenre?: string;
}

export interface IUpdateSongMediasBody {
  mediaId: string;
  version: string;
}

export interface IMergeSongsBody {
  workId: string;
  preliminaryWorkId: string;
}

export interface IContactBody {
  email: string;
  name: string;
  organizationName?: string;
  phone?: string;
  role?: string;
}

export interface IContactResponseItem {
  createdBy?: string;
  createdByName?: string;
  createdOnTime?: string;
  email?: string;
  id?: string;
  name?: string;
  organizationName?: string;
  phone?: string;
  role?: string;
  teamId?: string;
  teamName?: string;
  updatedBy?: string;
  updatedByName: string;
  updatedOnTime?: string;
}

export interface IContactGroupBody {
  contactIds?: string[];
  description?: string;
  name: string;
}

export interface IContactGroupResponseItem {
  createdBy?: string;
  createdByName?: string;
  createdOnTime?: string;
  description?: string;
  id?: string;
  name?: string;
  teamId?: string;
  teamName?: string;
  updatedBy?: string;
  updatedByName?: string;
  updatedOnTime?: string;
}

export interface IGetPitchesBody {
  title: string;
  status: string;
  type: string;
  contact: string;
  pitchedBy: string;
  sentDate: string;
  teamId: string;
  pagination: {
    skip: number;
    take: number;
    sort: {
      fieldName: string;
      direction: string;
    };
  };
  filter: {
    filterType: string;
    userId: string;
    teamIds: string[];
  };
}

export interface IAddMediasToManualPitchBody {
  mediaId: string;
  result: string;
}

export interface IAddPitchSystemActivity {
  pitchId: string;
  mediaIds: string[];
  contact: string;
  activity: string;
}

export interface IShareInternalBody {
  // Super Admin
  teamIds?: string[];
  userIds?: string[];
  // Label user
  emails?: string[];
}

// Download

export enum DownloadStatuses {
  STARTED = 'STARTED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
}

export interface IDownloadReport {
  id: string;
  fileName?: string;
  params?: IRequestParams;
  shouldCallGenerate?: boolean;
}

export interface IDownloadSongs {
  ids: string[];
  params?: IRequestParams;
  generatedId?: string;
}

export interface IDownloadPlaylist {
  id: string;
  mediaCount?: number;
  name?: string;
  params?: IRequestParams;
  shouldCallGenerate?: boolean;
}

export enum DownloadPublicTypes {
  playlist = 'playlist',
  pitch = 'pitch',
}

export interface IDownloadPitchesSharedSongs {
  type: DownloadPublicTypes;
  code: string;
  mediaCount?: number;
  name?: string;
  params?: IRequestParams;
  shouldCallGenerate?: boolean;
}

export interface IRegisterUserBody {
  email: string;
  company: string;
  firstName: string;
  lastName: string;
  role: UserRoleCodes;
}

export interface ITeamBody {
  name: string;
  parentId: string | null;
}

export interface ITeamResBody {
  id: string;
  isInternal: boolean;
  name: string;
  parent: ITeamResBody | null;
}

export interface IGetHoldsBody {
  skip: number;
  take: number;
  status?: HoldRequestTabKeys;
}

export interface IGetFeaturedPlaylistsBody {
  pagination: IPagination;
}

export interface ICreateFeaturedPlaylistBody {
  title: string;
  coverPhoto: string | null;
  releaseOn: string;
  expireOn: string;
  mediaIds: string[];
  keywords: string[];
}

export interface IGetNotifications extends IPagination {
  status?: NotificationStatuses;
}

export interface IUpdateNotificationStatusesBody {
  status: NotificationStatuses;
  notificationIds?: string[];
}

export interface IUpdateNotificationPreferenceBody {
  type: NotificationPlatformTypes;
  enabled: boolean;
}
