import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';
import { Popover, Tooltip } from '@material-ui/core';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import classNames from 'classnames';
import moment from 'moment-timezone';

import { DateRangePicker } from 'components/UI';

import { useDidUpdate } from 'hooks';
import { dateFormat } from 'utils';
import { isEmpty } from 'utils';

import { ReactComponent as ApproveIcon } from 'assets/approve-2.svg';
import { ReactComponent as CalendarIcon } from 'assets/calendar.svg';
import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import { ReactComponent as WarningIcon } from 'assets/warning.svg';
import styles from './DateRange.module.scss';

export interface IDateRangeValue {
  end: Date;
  start: Date;
}

export interface IDateRangeProps {
  className?: string;
  disabled?: boolean;
  error?: string;
  highlightValid?: boolean;
  label?: string | JSX.Element;
  labelClassName?: string;
  labelPlacement?: FormControlLabelProps['labelPlacement'];
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: (value: IDateRangeValue | null) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  required?: boolean;
  value: IDateRangeValue | null | undefined;
  isClearable?: boolean;
}

const dateToString = (value: IDateRangeValue | undefined | null): string => {
  return value ? `${moment(value.start).format(dateFormat)} to ${moment(value.end).format(dateFormat)}` : '';
};

const DateRange = forwardRef((props: IDateRangeProps, ref: React.Ref<IDateRangeProps>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(() => dateToString(props.value));

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const empty = useMemo(() => isEmpty(props.value, true), [props.value]);

  const valid = useMemo(
    () => props.highlightValid && !props.error && !empty,
    [empty, props.error, props.highlightValid]
  );

  const onInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (anchorEl) return;
    setFocused(false);
    props.onBlur && props.onBlur(e);
  };

  const onInputFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    props.onFocus && props.onFocus(e);
  };

  const onDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setInputValue('');
    props.onChange && props.onChange(null);
  };

  const onSubmit = (start: Date | undefined, end: Date | undefined) => {
    setAnchorEl(null);
    props.onChange && props.onChange(start && end ? { end, start } : null);
  };

  useDidUpdate(() => setInputValue(dateToString(props.value)), [props.value]);
  useImperativeHandle(ref, () => ({ ...props }));

  return (
    <>
      <FormControlLabel
        classes={{
          label: classNames(styles.label, props.labelClassName),
          labelPlacementStart: styles.labelPlacementStart,
          labelPlacementTop: styles.labelPlacementTop,
        }}
        className={props.className}
        control={
          <div
            className={classNames(styles.control, {
              [styles.disabled]: props.disabled,
              [styles.errorBorder]: props.error,
              [styles.focused]: focused,
              [styles.validBorder]: valid,
            })}
          >
            <input
              autoComplete="off"
              className={styles.input}
              disabled={props.disabled}
              name={props.name}
              onBlur={onInputBlur}
              onChange={(e) => setInputValue(e.target.value)}
              onClick={(e) => setAnchorEl(e.currentTarget)}
              onFocus={onInputFocus}
              placeholder={props.placeholder}
              readOnly
              type="text"
              value={inputValue}
            />
            <div className={styles.iconContainer}>
              {(props.error || props.required) && !valid && (
                <Tooltip
                  arrow
                  classes={{ arrow: styles.tooltipArrow, popper: styles.tooltipPopper, tooltip: styles.tooltip }}
                  onClose={() => setTooltipOpen(false)}
                  onOpen={() => setTooltipOpen(true)}
                  open={tooltipOpen && Boolean(props.error)}
                  placement="top-end"
                  title={props.error || ''}
                >
                  <div className={styles.tooltipIcon}>
                    {props.required ? (
                      <div className={classNames(styles.requiredIcon, { [styles.errorColor]: Boolean(props.error) })} />
                    ) : (
                      <WarningIcon className={classNames(styles.errorColor)} />
                    )}
                  </div>
                </Tooltip>
              )}
              {valid && <ApproveIcon className={classNames(styles.validColor)} />}
              {props.isClearable && inputValue ? (
                <DeleteIcon className={styles.deleteIcon} onClick={onDelete} />
              ) : (
                <CalendarIcon />
              )}
            </div>
          </div>
        }
        label={props.label}
        labelPlacement={props.labelPlacement || 'top'}
      />
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          onClose={() => setAnchorEl(null)}
          open
          PaperProps={{ classes: { root: styles.paper } }}
          transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        >
          <DateRangePicker
            endDate={props.value?.end}
            onSubmit={onSubmit}
            onCancel={() => setAnchorEl(null)}
            startDate={props.value?.start}
          />
        </Popover>
      )}
    </>
  );
});

export default DateRange;
