import { useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GridApi, RowClickedEvent } from 'ag-grid-community';
import classNames from 'classnames';

import { ConfirmPopup, NotificationPopup } from 'components/Popups';
import {
  ActionsCellRenderer,
  DateCellRenderer,
  NoDataAvailable,
  SelectionPanel,
  TooltipCellRenderer,
} from 'components/Reusable';
import DataGrid, { DataGridColDef, GetRowsParams } from 'components/UI/DataGrid';

import { useDidUpdate, useMemoSelector, useMount } from 'hooks';
import Api from 'services/Api';
import { getUserPermissions, getUserTeamIds } from 'store';
import { DEFAULT_ERROR_CONFIG, IColumnConfig, Paths, TABLE_PAGE_SIZE, UserPreferenceTypes } from 'utils';

import { ReactComponent as DeleteIcon } from 'assets/remove.svg';
import dataGridStyles from 'styles/modules/DataGridTheme.module.scss';
import styles from './Contacts.module.scss';

import { IContact, IContactGroup } from '.';

export interface IGroupGridProps {
  reloadHash?: string;
}

const getColumns = ({
  onDelete,
  hideActions,
}: {
  onDelete: (contact: IContactGroup) => void;
  hideActions: boolean;
}): DataGridColDef<IContactGroup>[] => [
  ...(!hideActions
    ? [
        {
          checkboxSelection: true,
          field: '',
          headerCheckboxSelection: true,
          suppressColumnsToolPanel: true,
          width: 60,
        },
      ]
    : []),
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'title',
    flex: 6,
    headerName: 'Title',
    suppressColumnsToolPanel: true,
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'createdBy',
    flex: 3,
    headerName: 'Created By',
  },
  {
    cellRendererFramework: DateCellRenderer,
    field: 'createdOn',
    flex: 2,
    headerName: 'Created On',
  },
  {
    cellRendererFramework: TooltipCellRenderer,
    field: 'modifiedBy',
    flex: 2,
    headerName: 'Modified By',
  },
  {
    cellClass: 'overflow-visible',
    cellRendererFramework: ({ data }) => (
      <ActionsCellRenderer
        buttons={[{ onClick: () => data && onDelete(data), text: <DeleteIcon />, tooltip: 'Delete Group' }]}
      />
    ),
    field: 'actions',
    headerName: 'Actions',
    pinned: 'right',
    suppressColumnsToolPanel: true,
    width: 100,
    hide: hideActions,
  },
];

const GroupGrid = (props: IGroupGridProps) => {
  const [delData, setDelData] = useState<IContactGroup[]>();
  const [notification, setNotification] = useState<string | undefined>();
  const [reloadHash, setReloadHash] = useState(props.reloadHash);
  const [selectedItems, setSelectedItems] = useState<IContactGroup[]>([]);
  const [columnsFromBackend, setColumnsFromBackend] = useState<DataGridColDef<IContactGroup>[]>([]);
  const gridRef = useRef<GridApi | null>(null);
  const history = useHistory();

  const { teamIds, permissions } = useMemoSelector((state) => ({
    teamIds: getUserTeamIds(state),
    permissions: getUserPermissions(state),
  }));

  const columns = useMemo(() => {
    const defaultColumns = getColumns({
      onDelete: (data) => setDelData([data]),
      hideActions: !permissions.contactActions,
    });

    return columnsFromBackend.length
      ? [...columnsFromBackend.slice(0, columnsFromBackend.length - 1), defaultColumns[defaultColumns.length - 1]]
      : defaultColumns;
  }, [permissions.contactActions, columnsFromBackend]);

  const getRows = ({ startRow }: GetRowsParams) =>
    Api.getGroups(
      { filter: { teamIds }, skip: startRow, take: TABLE_PAGE_SIZE },
      { errorPopupConfig: DEFAULT_ERROR_CONFIG }
    );

  const onDeleteConfirmed = async () => {
    if (delData && delData.length > 0) {
      await Promise.all(
        delData?.map(({ id }) => Api.deleteGroup(id || '', { errorPopupConfig: DEFAULT_ERROR_CONFIG }))
      );
      setDelData(undefined);
      setNotification('Group Deleted');
      setReloadHash(new Date().toISOString());
    }
  };

  const onRowClicked = (e: RowClickedEvent) => {
    const id = (e.data as IContactGroup).id;
    if (id) history.push(`${Paths.contactGroups}/${id}`);
  };

  useDidUpdate(() => setReloadHash(props.reloadHash), [props.reloadHash]);

  useMount(async () => {
    try {
      const preferences = await Api.getUserPreferencesByPreferenceName({ pathId: UserPreferenceTypes.contactsGroup });
      const preference = JSON.parse(preferences?.preference);

      if (Array.isArray(preference?.columns)) {
        const newColumns = columns.map((item) => {
          const isExistingFieldVisible = preference?.columns.some(
            (elem: IColumnConfig) => elem.field === item.field && elem.hide
          );

          return { ...item, hide: isExistingFieldVisible };
        });

        setColumnsFromBackend(newColumns);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug(err);
    }
  });

  const onChangeColumnsConfig = (columns: DataGridColDef<IContact>[]) => {
    Api.createUpdateUserPreferences(
      { columns: columns.map((item) => ({ field: item.field, hide: !!item.hide })) },
      { pathId: UserPreferenceTypes.contactsGroup }
    );
    setColumnsFromBackend(columns);
  };

  return (
    <div className={classNames(dataGridStyles.dataGridWithSelectionPanel, styles.dataGridFullHeight)}>
      <SelectionPanel
        buttons={[{ onClick: (nodes) => setDelData(nodes.map(({ data }) => data)), text: 'Delete' }]}
        gridRef={gridRef}
        selectedItems={selectedItems}
      />
      <DataGrid
        className={classNames(dataGridStyles.dataGrid, styles.dataGridFullHeight)}
        columns={columns}
        datasource={{ getRows, pageSize: TABLE_PAGE_SIZE, type: 'infinite' }}
        defaultColDef={{ suppressMovable: true }}
        gridId="contacts-groups"
        noRowsOverlayComponentFramework={() => <NoDataAvailable />}
        onRowClicked={onRowClicked}
        onSelectionChanged={setSelectedItems}
        ref={gridRef}
        reloadHash={reloadHash}
        rowHeight={54}
        rowSelection="multiple"
        suppressRowClickSelection
        onChangeColumnsConfig={onChangeColumnsConfig}
      />
      <ConfirmPopup
        btnDoneText="Delete"
        isOpen={(delData || []).length > 0}
        mainText={
          delData?.length === 1
            ? 'Deleting this group will remove the contacts from group, proceed?'
            : 'Deleting these group wills remove the contacts from groups, proceed?'
        }
        onClickSubmit={onDeleteConfirmed}
        questionText={
          delData?.length === 1
            ? `Delete “${delData[0].title}” and Remove Contacts?`
            : 'Delete selected groups and Remove Contacts?'
        }
        setIsOpen={() => setDelData(undefined)}
      />
      <NotificationPopup
        isOpen={Boolean(notification)}
        setIsOpen={() => setNotification(undefined)}
        text={notification || ''}
      />
    </div>
  );
};

export default GroupGrid;
