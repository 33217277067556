import { forwardRef, useImperativeHandle, useState } from 'react';
import { Controller, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import { WriterForm } from 'components/Reusable';
import { NewAutocomplete as Autocomplete } from 'components/UI';

import { useDidUpdate } from 'hooks';
import Api from 'services/Api';

import styles from './SongsUpload.module.scss';

import { ISongUploadItem, IWriterItem } from 'types';

export interface IWritersFormProps {
  item?: ISongUploadItem;
}

export type WritersFormApi = UseFormReturn<ISongUploadItem, Record<string, unknown>>;

const WritersForm = forwardRef(({ item }: IWritersFormProps, ref) => {
  const methods = useForm<ISongUploadItem>({ defaultValues: item || {} });
  const [addNewForm, setAddNewForm] = useState(false);
  const writers = useWatch({ control: methods.control, name: 'writers' });

  useDidUpdate(() => methods.reset(item), [item]);
  useImperativeHandle(ref, () => ({ ...methods }));

  const onNewWriterAdded = ({ id, title }: IWriterItem) => {
    setAddNewForm(false);
    const newValue = [...(writers || [])];
    if (id) newValue.push({ id, title: title || '' });
    methods.setValue('writers', newValue);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Controller
            control={methods.control}
            name="writers"
            render={({ field }) => (
              <Autocomplete
                {...field}
                addNewButtonOnClick={() => setAddNewForm(true)}
                addNewButtonText="Add New Writer"
                className={styles.control}
                Icon={<></>}
                label="Writer(s)"
                multiple
                onResolveSuggestions={(text) => Api.getWriters(text).catch(() => [])}
              />
            )}
          />
        </Grid>
      </Grid>
      {addNewForm && <WriterForm onClose={() => setAddNewForm(false)} onSubmit={onNewWriterAdded} />}
    </>
  );
});

export default WritersForm;
