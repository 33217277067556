import classNames from 'classnames';

import { TagsList } from 'components/Reusable';
import { ITagsListProps } from 'components/Reusable/TagsList/TagsList';

import { ReactComponent as FilterIcon } from 'assets/filter.svg';
import styles from './TableFilters.module.scss';

interface ITableFiltersProps<T, R> extends ITagsListProps<T, R> {
  className?: string;
  isOpenFiltersPopup: boolean;
  onClickFilterIcon: () => void;
  onlyFirstCharCapitalizedItems?: Array<keyof T>;
  title?: string;
}

const TableFilters = <T, R>({
  data,
  onDeleteTag,
  onClickFilterIcon,
  isOpenFiltersPopup,
  dataTitles,
  onlyFirstCharCapitalizedItems = [],
  className,
  title,
}: ITableFiltersProps<T, R>) => {
  return (
    <div className={classNames(styles.filtersContainer, className)}>
      <TagsList
        title={title}
        tagsContainerClass={styles.tagsContainer}
        tagClass={styles.tag}
        data={data}
        dataTitles={dataTitles}
        onDeleteTag={onDeleteTag}
        onlyFirstCharCapitalizedItems={onlyFirstCharCapitalizedItems}
      />
      <FilterIcon
        className={classNames(styles.filterIcon, { [styles.openedFilters]: isOpenFiltersPopup })}
        onClick={onClickFilterIcon}
      />
    </div>
  );
};

export default TableFilters;
