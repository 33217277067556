import { batch } from 'react-redux';
import { GridApi } from 'ag-grid-community';
import classNames from 'classnames';

import { SongTitleCell } from 'components/PitchDetails';
import pitchDetailsStyles from 'components/PitchDetails/PitchDetails.module.scss';
import { Tooltip } from 'components/UI';
import { DataGridColDef } from 'components/UI/DataGrid';

import Api, { downloadAudio, downloadPitchesSharedSongs, DownloadPublicTypes } from 'services/Api';
import { dispatch, setActiveTrack, setCurrentTracks } from 'store';
import { getDataGridItems, getSongVersion, immediatelyPlayAudio } from 'utils';

import { ReactComponent as DownloadIcon } from 'assets/download.svg';
import { ReactComponent as PlayCircleIcon } from 'assets/play-circle.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './ExternalPage.module.scss';

import { PlayIconCell } from './';
import { IMarkerBE } from 'types';

export interface IExternalMedia {
  id: string;
  pitchId: string;
  contactId: string;
  mediaId: string;
  title: string;
  version: string;
  workId: string;
  songId: string;
  fileName: string;
  order: string;
  creators: string[];
  isDownloadable: boolean;
  mediaPath: string;
  waveForm: null | string;
  markers: null | IMarkerBE[];
  code?: string;
  dataId: string;
  dataTitle: string;
  dataCode: string;
  downloadType: DownloadPublicTypes;
}

interface IExternalContact {
  id: string;
  contact: string | null;
  email: string | null;
  isTeamGroup: boolean;
}

export enum ExternalDataStatuses {
  expired = 'expired',
  deactivated = 'deactivated',
  error = 'error',
}

export enum ExternalDataErrorMessagesByStatus {
  expired = 'Sorry, the link has expired. \n Please contact sender to renew your access.',
  deactivated = 'Sorry, the shared songs have been archived.',
  error = 'Oops!, Something went wrong. Please try again later.',
}

export interface IExternalData {
  id: string;
  title: string;
  expireOn: string;
  message: string;
  status: ExternalDataStatuses;
  type: string;
  teamName: string;
  teamId: string;
  isDownloadable: boolean;
  artist: string;
  label: string;
  createdByName: string;
  medias: IExternalMedia[];
  contacts: IExternalContact[];
}

export enum ExternalColumnFields {
  title = 'title',
  writers = 'writers',
  actions = 'actions',
  empty = '',
}

export enum ExternalColumnHeaderNames {
  title = 'Song Title',
  writers = 'Writer(s)',
  empty = '',
}

export enum ExternalActivities {
  view = 'VIEW',
  download = 'DOWNLOAD',
  play = 'PLAY',
}

export enum ExternalTypes {
  pitch = 'pitch',
  share = 'share',
  alert = 'alert',
}

export const ExternalPageTitleActions = {
  [ExternalTypes.pitch]: 'Pitched',
  [ExternalTypes.share]: 'Shared',
  [ExternalTypes.alert]: 'Search Alert',
};

export interface IPlaySong {
  api: GridApi;
  data?: IExternalMedia;
  isFromHeader?: boolean;
}

export const playSong = ({ api, data, isFromHeader }: IPlaySong) => {
  const items = getDataGridItems(api) as IExternalMedia[];

  batch(() => {
    dispatch(
      setCurrentTracks(
        items.map((item) => ({
          mediaId: item.id || '',
          id: item.workId || item.songId,
          title: item.title || '',
          path: item.fileName || item.mediaPath || '',
          writers: item.creators || [],
          ...(item.markers && { markers: item.markers }),
          ...(item.waveForm && { waveForm: JSON.parse(item.waveForm) }),
          songCode: item.code,
          version: item.version || '',
        }))
      )
    );

    dispatch(
      setActiveTrack({
        index: isFromHeader
          ? 0
          : items.findIndex((el) => (el.workId ? el.workId === data?.workId : el.id === data?.id)),
      })
    );
  });

  immediatelyPlayAudio();
};

export const getExternalColumns = (isDownloadable: boolean, isMobile: boolean): DataGridColDef<IExternalMedia>[] => {
  const externalColumns: DataGridColDef<IExternalMedia>[] = [
    {
      field: ExternalColumnFields.empty,
      headerName: ExternalColumnHeaderNames.empty,
      headerClass: styles.headerCell,
      headerComponentFramework: ({ api }: { api: GridApi }) => (
        <PlayCircleIcon onClick={() => playSong({ api, isFromHeader: true })} />
      ),
      cellRendererFramework: ({ data, api }) => <PlayIconCell data={data} api={api} />,
      maxWidth: 50,
    },
    {
      field: ExternalColumnFields.title,
      headerName: ExternalColumnHeaderNames.title,
      headerClass: styles.headerCell,
      cellClass: pitchDetailsStyles.songTitleCell,
      cellRendererFramework: ({ data }) => (
        <SongTitleCell
          isExternal
          id={data?.id}
          title={data?.title}
          version={data?.version}
          className={globalStyles.f13h16SuisseSB_white}
          writers={data?.creators}
        />
      ),
    },
  ];

  if (!isMobile) {
    externalColumns.push({
      field: ExternalColumnFields.writers,
      headerName: ExternalColumnHeaderNames.writers,
      headerClass: styles.headerCell,
      cellClass: globalStyles.f13h16SuisseSB_white,
      cellRendererFramework: ({ data }) => (
        <Tooltip isDark text={data?.creators?.join(' / ') || '-'}>
          <span className={classNames(globalStyles.textEllipsis, styles.writersText)}>
            {data?.creators?.join(' / ') || '-'}
          </span>
        </Tooltip>
      ),
    });
  }

  if (isDownloadable) {
    externalColumns.push({
      field: ExternalColumnFields.empty,
      headerName: ExternalColumnHeaderNames.empty,
      headerClass: styles.headerCell,
      headerComponentFramework: (params: { api: GridApi }) => (
        <DownloadIcon
          onClick={async () => {
            const rowData = getDataGridItems(params.api);

            const data = rowData[0] as IExternalMedia;

            if (rowData.length === 1) {
              return await downloadAudio(
                data.fileName || data.mediaPath,
                `${data.title} (${getSongVersion(data.version)})`
              );
            }

            data &&
              (await downloadPitchesSharedSongs({
                code: data.dataCode,
                type: data.downloadType,
                name: data.dataTitle,
                mediaCount: rowData.length,
              }));
          }}
        />
      ),
      cellRendererFramework: ({ data }) => (
        <DownloadIcon
          onClick={async () => {
            if (!data) return;

            await downloadAudio(data.fileName || data.mediaPath, `${data.title} (${getSongVersion(data.version)})`);

            if (data.pitchId) {
              await Api.addPitchSystemActivity({
                pitchId: data.pitchId,
                mediaIds: [data.mediaId],
                contact: data?.contactId || '',
                activity: ExternalActivities.download,
              });
            }
          }}
        />
      ),
      maxWidth: 50,
    });
  }

  return externalColumns;
};
