import { Dispatch, MouseEvent, MutableRefObject, RefObject, SetStateAction } from 'react';

import { ICurrentTrack, SongStatusTypes, SongsWorkTypes } from 'types';

export interface TrackInfo {
  id: string;
  songId?: string;
  title: string;
  path: string;
  peeks: number[];
  writers: string[];
  markers: IMarker[];
  version: string;
  status: SongStatusTypes | '';
  duration: number;
  type?: SongsWorkTypes;
  labelView?: boolean;
  teamId?: string | null;
  featured?: boolean;
}

export interface MusicPlayerProps {
  tracks: TrackInfo[];
  setTracks?: Dispatch<SetStateAction<TrackInfo[]>>;
  updateTracks: (newTrack: ICurrentTrack) => Promise<void>;
  onProgress?: (progress: number) => void;
  onTrackChange?: (track: string) => void;
  isExternal?: boolean;
  isDark?: boolean;
  addPlayActivity?: () => void;
  openMobileMarkersView?: boolean;
  setOpenMobileMarkersView?: Dispatch<SetStateAction<boolean>>;
}

export enum PlayerPopups {
  marker = 'marker',
  plus = 'plus',
  volume = 'volume',
  more = 'more',
}

export enum MarkerValues {
  title = 'title',
  xAxisPercent = 'xAxisPercent',
  markerTime = 'markerTime',
}

export interface IMarker {
  id: string;
  [MarkerValues.title]: string;
  [MarkerValues.xAxisPercent]: number;
  [MarkerValues.markerTime]: number;
  disabled?: boolean;
}

export enum ActionTypes {
  add = 'add',
  remove = 'remove',
  edit = 'edit',
}

export interface IActionMarker {
  actionType: ActionTypes;
  marker?: IMarker;
  type?: string;
  value?: string | number;
}

export enum LikeDislike {
  like = 'like',
  dislike = 'dislike',
}

export interface IFavoriteConfig {
  isFavorite: boolean;
  animation: LikeDislike | '';
}

export interface IPlayControlsProps extends MusicPlayerProps, Omit<IPlayerActions, 'disableIcon'> {
  track: TrackInfo;
  aRef: MutableRefObject<HTMLAudioElement | undefined>;
  setTrack: Dispatch<SetStateAction<TrackInfo>>;
  setDuration: Dispatch<SetStateAction<number>>;
  setProgress: Dispatch<SetStateAction<number>>;
  setTime: Dispatch<SetStateAction<number>>;
  isExternal?: boolean;
}

export interface IAddToPlaylist {
  id: string;
  title: string;
}

export interface IPlayerNotificationsProps {
  openFavoriteSnackbar: boolean;
  setOpenFavoriteSnackbar: Dispatch<SetStateAction<boolean>>;
  isFavorite: boolean;
  addToPlaylist: IAddToPlaylist;
  setAddToPlaylist: Dispatch<SetStateAction<IAddToPlaylist>>;
}

export interface IFavoriteContainerProps {
  favoriteConfig: IFavoriteConfig;
  toggleFavorite: (e: MouseEvent<HTMLDivElement>) => void;
  disableIcon: boolean;
}

export interface IPlaybarHeaderTitleProps extends Omit<IFavoriteContainerProps, 'disableIcon'> {
  track: TrackInfo;
  isDark?: boolean;
}

export interface IPlayerPopupsProps {
  isShow: boolean;
  popupContainerRef: RefObject<HTMLDivElement>;
  trackId?: string;
  onClose?: () => void;
  setAddToPlaylistName?: Dispatch<SetStateAction<string>>;
  setOpenFavoriteSnackbar?: Dispatch<SetStateAction<boolean>>;
}

export interface IPlayerActions {
  checkIsPopup: (type: PlayerPopups) => boolean;
  changeOpenPopup: (type: PlayerPopups | '') => void;
  actionMarker: (props: IActionMarker) => void;
  popupContainerRef: RefObject<HTMLDivElement>;
  disableIcon: boolean;
}
