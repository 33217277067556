/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  BodyScrollEvent,
  ColDef,
  Column,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  RowNode,
  SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { AgGridReactProps } from 'ag-grid-react/lib/interfaces';

import styles from './DataGrid.module.scss';

import ColConfig from './ColConfig';
import extendGridFeatures from './extensions';
import CustomFilter from './filters/CustomFilter';
import { gridPrivateInvocation } from './gridApi';
import { useIsMounted } from './hooks';
import {
  Any,
  CellRendererProps,
  DataGridColDef,
  DataGridDS,
  DataGridProps,
  FiltersModel,
  InfiniteDS,
  RowNodeExt,
} from './IDataGrid';
import {
  autoSizeActionsColumn,
  debounceCallback,
  getThemeClass,
  restoreColumnsSettings,
  sleepPromise,
  uuidv4,
} from './utils';

extendGridFeatures(RowNode);

// eslint-disable-next-line react/display-name
const DataGrid = <T,>(props: DataGridProps<T>, ref: React.ForwardedRef<GridApi>) => {
  const [instanceId] = useState(uuidv4());
  const [rowData, setRowData] = useState<T[]>();
  const [total, setTotal] = useState<number | undefined>((props.datasource as { total?: number }).total);
  const [error, setError] = useState<string>();
  const [confHash, setConfHash] = useState<string>();
  const [height, setHeight] = useState<string>();
  const isMounted = useIsMounted();

  const gridApiRef = useRef<GridApi>();
  const colsRef = useRef<DataGridColDef<T>[]>();
  const containerRef = useRef<HTMLDivElement>(null);
  const loadingRef = useRef<boolean>(false);
  const initLoadRef = useRef<boolean>(true);
  const extLoadingRef = useRef<boolean>(props.loading || false);
  const filtersRef = useRef<FiltersModel>(props.initialFilterModel || {});
  const rowsLenRef = useRef<number>(0);
  const loadingTs = useRef<Date>();

  const loadPagedData = useCallback(
    async (_event: string, startRow: number, endRow: number, filters: FiltersModel | null = null): Promise<void> => {
      if (!isMounted()) return;
      const api = gridApiRef.current as GridApi;

      // To compare after loading is done and ignore a result if there was any other concurrent load
      const loadingSnapshot = new Date();
      loadingTs.current = loadingSnapshot;

      // console.log(_event);

      // External loading mode, when we block grid data loading on purpose
      if (extLoadingRef.current) {
        addLoadingEffect(api, true);
        setRowData(genEmptyRows(10, getRowKey(props.datasource))); // to show loading skeleton
        return;
      }

      // Update grid filters model
      if (filters !== null) {
        const model = api.getFilterModel();
        // Force redefine existing filters
        for (const m in model) {
          if (typeof filters[m] === 'undefined') {
            api.getFilterInstance(m)?.setModel({});
          }
        }
        const nextFilters: FiltersModel = {};
        for (const m in filters) {
          const i = api.getFilterInstance(m);
          i && i.setModel(filters[m]);
          if (i === null) {
            nextFilters[m] = filters[m];
          }
        }
        if (Object.keys(nextFilters).length > 0) {
          api.setFilterModel(nextFilters);
        }
      }

      if (isInfiniteDS(props.datasource?.type)) {
        const ds = props.datasource as InfiniteDS<T>;
        loadingRef.current = true;
        const totalLength = ds.total;
        const pageSize = ds.pageSize || 50;
        const filterModel = filters !== null ? filters : api ? api.getFilterModel() : {};

        if (initLoadRef.current) {
          initLoadRef.current = false;
        }
        if (startRow === 0) {
          rowsLenRef.current = 0;
          addLoadingEffect(api);
          setTotal(totalLength);
          setRowData((rows) => {
            const rowLen = (rows || []).length;
            return genEmptyRows(rowLen < 10 ? rowLen : 10, getRowKey(ds));
          }); // to show loading skeleton
        } else {
          // setRowData((rows) => [...(rows || []), {} as Any]); // add one item
          setRowData((rows) => [...(rows || []), ...genEmptyRows(pageSize, getRowKey(ds))]);
        }
        await ds
          .getRows({
            startRow,
            endRow,
            filterModel,
            sortModel: (api?.getColumnDefs() || [])
              .filter((c: ColDef) => c.sort)
              .map((c: ColDef) => ({ fieldName: c.colId, direction: c.sort })), // api.getSortModel(), // getSortModel() is deprecated, sort information is now part of Column State. Please use columnApi.getColumnState() instead.
            context: undefined,
            filters: filtersRef.current,
          })
          .then((nextPage = []) => {
            if (!isMounted) return null; // ignore updating state when component is already unmount
            if (loadingTs.current !== loadingSnapshot) return null; // Ignore this load

            setRowData((rows) => {
              if (nextPage.length < endRow - startRow) {
                setTotal(totalLength || (rows || []).filter(notEmptyRow).length + nextPage.length);
              }
              // Verify that next page doesn't contain duplicates (same rowKey/id values)
              const rowKey = getRowKey(ds) || '';
              const ids = rowKey ? (rows || []).filter(notEmptyRow).map((r: Any) => r[rowKey]) : [];
              const newData = [
                ...(rows || []).filter(notEmptyRow),
                ...nextPage.filter((r: Any) => {
                  const isUnique = rowKey ? ids.indexOf(r[rowKey]) === -1 : true;
                  // Ignore rows with the same rowKey value (trim duplicates)
                  // Show warning only for deployed apps to avoid misleading messages as a result of hot reload and triggering same loads
                  if (!isUnique && window.location.href.indexOf('//localhost:') === 0)
                    console &&
                      // eslint-disable-next-line no-console
                      console.warn(
                        `A not unique rowKey value is detected: ${rowKey}=${r[rowKey]}, that is a potential issue with the API or loading process`
                      );
                  return isUnique;
                }),
              ];
              rowsLenRef.current = newData.length;

              return newData;
              // return [...(rows || []).filter(notEmptyRow), ...nextPage]; // this doesn't trim duplicates
            });
            loadingRef.current = false;
            removeLoadingEffect(api);
            return null;
          })
          .catch((ex) => {
            if (!isMounted) return null;
            setError(ex.message || `${ex}`);
            loadingRef.current = false;
            removeLoadingEffect(api);
          });
      }
    },
    [isMounted, props.datasource]
  );

  // External loading management
  useEffect(() => {
    const api = gridApiRef.current as GridApi;
    if (props.loading === true && !extLoadingRef.current && api) {
      extLoadingRef.current = true;
      addLoadingEffect(api);
      setRowData(genEmptyRows(10, getRowKey(props.datasource))); // to show loading skeleton
    }
    if (props.loading === false && extLoadingRef.current && api) {
      extLoadingRef.current = false;
      removeLoadingEffect(api);
      loadPagedData('props.datasource or props.loading', 0, (props.datasource as { pageSize?: number }).pageSize || 50);
    }
  }, [loadPagedData, props.datasource, props.loading]);

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const gridProps: AgGridReactProps = useMemo(() => {
    const gp: AgGridReactProps = {};
    if (isInfiniteDS(props.datasource?.type)) {
      const ds = props.datasource as InfiniteDS<T>;
      gp.suppressScrollOnNewData = true;
      gp.onSortChanged = () => {
        const api = gridApiRef.current as GridApi;
        gridPrivateInvocation(api, (e) => {
          e.bodyViewport.scrollTop = 0;
        });
        loadPagedData('onSortChanged', 0, ds.pageSize || 50);
      };
      const rowKey = getRowKey(ds);
      if (rowKey) {
        // Immutable data allows preventing unwanted rerendering
        gp.immutableData = true;
        gp.getRowNodeId = (r) => r[rowKey];
      }
    } else if (props.datasource?.type === 'classic') {
      gp.rowData = props.datasource.rowData;
    }
    return gp;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // props.datasource, loadPagedData, props.initialFilterModel

  // Update external filters
  useEffect(() => {
    const filterCurrent = filtersRef.current;
    filtersRef.current = props.initialFilterModel || {}; // need to update right away
    if (gridApiRef.current) {
      const api = gridApiRef.current;
      const model = api.getFilterModel();
      try {
        // Run in try/catch to ignore potential circular exceptions
        if (JSON.stringify({ ...(filterCurrent || {}), ...model }) === JSON.stringify(props.initialFilterModel)) return;
        if (JSON.stringify(model) === JSON.stringify(props.initialFilterModel)) return;
      } catch (ex) {
        /**/
      }

      // filtersRef.current = props.initialFilterModel || {};
      if (isInfiniteDS(props.datasource?.type) && (props.ignoreLoadingOnFiltersChange || !loadingRef.current)) {
        const ds = props.datasource as InfiniteDS<T>;
        loadPagedData('props.initialFilterModel', 0, ds.pageSize || 50, props.initialFilterModel);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialFilterModel]);

  // Force reload hook
  useEffect(() => {
    if (!initLoadRef.current && isInfiniteDS(props.datasource?.type)) {
      const ds = props.datasource as InfiniteDS<T>;
      loadPagedData('props.reloadHash', 0, ds.pageSize || 50);
    }
    initLoadRef.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadHash]);

  useEffect(() => {
    if (typeof window === 'undefined' || !props.getGridHeight) return;
    const onWindowResize = () => {
      if (containerRef.current) {
        // const top = containerRef.current.offsetTop;
        const top = containerRef.current.getBoundingClientRect().top;
        // console.log(containerRef.current);
        props.getGridHeight && setHeight(props.getGridHeight(top));
      }
    };
    onWindowResize();
    window.addEventListener('resize', onWindowResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [props, props.getGridHeight]);

  // Tracks custom infinite scroll which allows using some APIs
  // disabled in native AGGrid infinite scroll implementations
  const onBodyScroll = useCallback(
    async (event: BodyScrollEvent) => {
      props.onBodyScroll && props.onBodyScroll(event);
      if (isInfiniteDS(props.datasource?.type)) {
        const { api } = event;

        const ds = props.datasource as InfiniteDS<T>;
        const loadedLength = rowsLenRef.current;
        const pageSize = ds.pageSize || 50;

        const { scrollHeight, scrollTop } = gridPrivateInvocation(api, (e) => e.bodyViewport) || {
          scrollHeight: 0,
          scrollTop: 0,
        };

        const avgItemHeight = scrollHeight / (loadedLength || 10);

        const reachedLoadingArea = scrollHeight - scrollTop <= avgItemHeight * pageSize * 0.5; // 50% of a page left

        if (reachedLoadingArea) {
          const currentRowsCount = loadedLength;
          // Prevent instant call on initial load
          if (currentRowsCount === 0) return;
          // Prevent loading then more than provided total number is loaded
          if (total && currentRowsCount >= total) return;

          if (!loadingRef.current) {
            await loadPagedData('onBodyScroll', loadedLength, loadedLength + pageSize);

            // Check if we reached scroll bottom to trigger load next chunk automatically
            try {
              const { offsetHeight, scrollHeight, scrollTop } = gridPrivateInvocation(
                api,
                (e) => e.bodyViewport
              ) as HTMLElement;
              const reachedBottom = scrollHeight - (scrollTop + offsetHeight) === 0;
              if (reachedBottom) {
                setTimeout(() => onBodyScroll(event), 500);
              }
            } catch (ex) {
              /**/
            }
          }
        }
      }
    },
    [props, loadPagedData, total]
  );

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      const { api } = event;
      gridApiRef.current = api;
      api.stateReducers = { setRowData, setTotal };
      // Forwarding ref to parent container
      if (ref) typeof ref === 'function' ? ref(api) : (ref.current = api);
      // Initial data load for custom infinite scroll
      if (isInfiniteDS(props.datasource?.type)) {
        const ds = props.datasource as InfiniteDS<T>;
        loadingRef.current = true;
        addLoadingEffect(api, true);
        setRowData(genEmptyRows(10, getRowKey(ds))); // to show loading skeleton
        // Block grid loading and wait a tick+ for potentially redefined initial filters
        setTimeout(() => {
          loadPagedData('onGridReady', 0, ds.pageSize || 50, filtersRef.current)
            .then((res) => {
              api.sizeColumnsToFit();
              props.refreshHeaderOnAutoSizeActionsColumn && api.refreshHeader();
              return res;
            })
            .catch(() => ({}));
        }, 50);
      }
      api.redrawRows();
      props.onGridReady && props.onGridReady(event);
    },
    [props, ref, loadPagedData]
  );

  const onSelectionChanged = useCallback(
    (event: SelectionChangedEvent) => {
      if (props.onSelectionChanged) {
        props.onSelectionChanged(
          event.api.getSelectedNodes().map((r) => r.data),
          event
        );
      }
    },
    [props]
  );

  const getRowClass = useCallback(
    (row: RowClassParams): string[] => {
      // Injects loading skeleton styling
      let rowClasses = [isEmptyRow(row.data) ? `${styles.loadingRow} loadingRow` : ''];
      // Adds custom styles
      if (props.getRowClass) {
        let rowClasses2 = props.getRowClass(row) || '';
        if (typeof rowClasses2 === 'string') {
          rowClasses2 = [rowClasses2];
        }
        rowClasses = [...rowClasses, ...rowClasses2];
      }
      return rowClasses;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.getRowClass]
  );

  const Error = props.errorRenderer
    ? props.errorRenderer
    : (p: { error: string }) => <div className={styles.error}>{p.error}</div>;

  // Extending columns
  const columnsModel: DataGridColDef<T>[] = useMemo(
    () => {
      if (colsRef.current) return colsRef.current;
      return restoreColumnsSettings(
        props.gridId,
        props.columns.map((col) => {
          // Extending NodeRow features
          if (col.cellRendererFramework) {
            const cellRendererFramework = col.cellRendererFramework;
            col.cellRendererFramework = (p: CellRendererProps<T>) => {
              p.node.setDetailsRow = function (
                this: RowNodeExt,
                expanded: boolean,
                rowHeight = 0,
                accordionMode = false
              ) {
                // Accordion behaviors to collapse sibling nodes
                if (accordionMode && gridApiRef.current) {
                  const rowModel = (gridApiRef.current as Any).rowModel; // can be undefined in some cases
                  if (rowModel) {
                    const allOtherNodes: RowNode[] = rowModel.rootNode.allLeafChildren.filter(
                      ({ rowIndex, expanded }: RowNode) => rowIndex !== this.rowIndex && expanded
                    );
                    allOtherNodes.forEach((node) => {
                      node.setExpanded(false);
                      node.setMaster(false);
                    });
                  }
                }
                this.setExpanded(expanded); // !this.expanded);
                this.setMaster(expanded); // !this.master);
                if (rowHeight) {
                  // Async timeout is used here to avoid `[Violation] 'setTimeout' handler took` Chrome message
                  // eslint-disable-next-line promise/catch-or-return
                  sleepPromise(10).then(() => {
                    this.detailNode?.setRowHeight(rowHeight);
                    gridApiRef.current && gridApiRef.current.onRowHeightChanged();
                    return null;
                  });
                }
              }.bind(p.node);
              if (isEmptyRow(p.data)) return '';
              return cellRendererFramework(p);
            };
          }
          // Disable client-side sorting
          if (!col.comparator && isInfiniteDS(props.datasource?.type)) {
            col.comparator = () => 0;
          }
          // Suppress menu by default for custom filter
          if (col.filter === 'customFilter' && typeof col.suppressMenu === 'undefined') {
            col.suppressMenu = true;
          }
          return col;
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.gridId, props.columns, props.datasource?.type, confHash]
  );

  // Triggers re-memoing of columns on settings change
  const onColConfigChangeHandler = useCallback((columns: DataGridColDef<T>[], isFromOutside?: boolean) => {
    colsRef.current = columns;
    setConfHash(new Date().getTime().toString());
    !isFromOutside && props.onChangeColumnsConfig && props.onChangeColumnsConfig(columns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const themeClass = useMemo(() => getThemeClass(props.theme), [props.theme]);

  useEffect(() => {
    onColConfigChangeHandler(props.columns, true);
  }, [onColConfigChangeHandler, props.columns]);

  const onRowDataUpdated = useCallback(
    (e) => {
      if (loadingRef.current) return;

      setTimeout(() => {
        autoSizeActionsColumn(gridApiRef.current);

        const actionsCell = document.querySelector('.actionsCell') as HTMLElement;

        if (actionsCell && actionsCell.scrollWidth < actionsCell.offsetWidth) {
          setTimeout(() => autoSizeActionsColumn(gridApiRef.current), 200);
        }
      }, 100);
      props.onRowDataUpdated && props.onRowDataUpdated(e);
    },
    [props]
  );

  return (
    <div
      ref={containerRef}
      className={`${themeClass} ${styles.datagrid} ${props.className ? props.className : ''}`}
      style={{ ...(props.style || {}), height: height || props.style?.height }}
    >
      {!error && (
        <>
          <AgGridReact
            // Props redefine
            {...{
              ...(props as unknown as AgGridReactProps),
              datasource: undefined, // removes datasource as we use custom parameter
            }}
            // Core behaviors
            {...gridProps}
            // reactUi={true} // not yet ready for a switch, https://www.ag-grid.com/react-data-grid/reactui/
            rowData={
              props.datasource.type === 'infinite' ? rowData : staticRows(props.datasource.rowData, props.loading)
            }
            defaultColDef={{
              ...{ filter: 'customFilter', suppressMenu: true, unSortIcon: true },
              ...(props.defaultColDef || {}),
            }}
            rowHeight={props.rowHeight}
            rowSelection={props.rowSelection}
            getRowClass={getRowClass}
            frameworkComponents={{
              customFilter: CustomFilter,
              ...(props.frameworkComponents || {}),
            }}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            onBodyScroll={onBodyScroll}
            onRowDataUpdated={onRowDataUpdated}
            onColumnResized={(e) => {
              debounceCallback(`onColumnResized_${instanceId}`, () => {
                // e.api.redrawRows();
                e.api.refreshCells({ columns: e.columns as Column[], force: true });
              });
            }}
            // Performance related settings
            rowBuffer={70}
            // suppressAnimationFrame={true}
            // debounceVerticalScrollbar={true}
          >
            {columnsModel.map((f, i) => (
              <AgGridColumn key={`${f.field}-${i}`} {...f} />
            ))}
          </AgGridReact>
          {!props.disableColumnsConfig && (
            <ColConfig<T>
              gridId={props.gridId}
              columns={columnsModel}
              theme={props.theme}
              onChange={onColConfigChangeHandler}
            />
          )}
        </>
      )}
      {error && <Error error={error} />}
      {props.loading && <div className={styles.loadingOverlay} />}
    </div>
  );
};

const staticRows = <T,>(rows: T[] = [], loading = false): T[] => {
  return [...rows, ...(loading ? new Array(10).fill({}) : [])];
};

const notEmptyRow = (row: Any) => row && Object.keys(row || {}).length > 0 && !row.__empty;

const isEmptyRow = (row: Any) => !notEmptyRow(row);

const isInfiniteDS = (type?: string): boolean => type === 'infinite'; // || type === 'infinite2';

const addLoadingEffect = (api: GridApi, initial = false) => {
  gridPrivateInvocation(api, (e) => {
    if (initial) {
      e.gridBody.classList.add(styles.initialLoading);
      e.gridBody.classList.add('initial-loading');
    }
    e.gridBody.classList.add(styles.loading);
  });
};

const removeLoadingEffect = (api: GridApi) => {
  gridPrivateInvocation(api, (e) => {
    e.gridBody.classList.remove(styles.initialLoading);
    e.gridBody.classList.remove('initial-loading');
    e.gridBody.classList.remove(styles.loading);
  });
};

const genEmptyRows = (count: number, rowKey: string | null): Array<Any & { id: string; __empty: boolean }> => {
  return Array(count)
    .fill(null)
    .map(() => ({ [rowKey || 'id']: uuidv4(), __empty: true }));
};

const getRowKey = <T,>(ds: DataGridDS<T>): string | null => {
  const rowKey = ((ds || {}) as { rowKey?: string | null }).rowKey;
  return typeof rowKey === 'undefined' ? 'id' : rowKey;
};

const FRefDataGrid = forwardRef(DataGrid) as <T>(
  p: DataGridProps<T> & { ref?: React.Ref<GridApi> }
) => React.ReactElement;

export default FRefDataGrid;
