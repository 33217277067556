import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';

import { TextEditor } from 'components/UI';

import Api from 'services/Api';
import { DEFAULT_ERROR_CONFIG, isEmptyHtmlString } from 'utils';

import styles from './SongDetails.module.scss';

import { IAddPopupProps } from 'types';

const AddNotes = ({ addRef, setBtnDisabled }: IAddPopupProps) => {
  const { id } = useParams<{ id: string }>();

  const [note, setNote] = useState<string>('');

  useEffect(() => {
    setBtnDisabled(isEmptyHtmlString(note));
  }, [note, setBtnDisabled]);

  const onHandleSubmit = () => Api.addSongNote(id, note, { errorPopupConfig: DEFAULT_ERROR_CONFIG });

  useImperativeHandle(addRef, () => ({ onHandleSubmit }));

  return (
    <div className={styles.addNotes}>
      <div className={styles.addNotesTitle}>Message</div>
      <TextEditor value={note} onChange={(content) => setNote(content)} />
    </div>
  );
};

export default forwardRef(AddNotes);
