import { Controller, useForm, useWatch } from 'react-hook-form';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { Button, ContactOption, Dialog, DoubleBtnGroup } from 'components/Reusable';
import { NewAutocomplete as Autocomplete, NewTextField as TextField } from 'components/UI';

import { useDidUpdate, useMemoSelector } from 'hooks';
import Api from 'services/Api';
import { getUserTeamId, getUserTeamIds } from 'store';
import { debouncePromise, DEFAULT_ERROR_CONFIG, isEmpty } from 'utils';

import { ReactComponent as PlusCircleIcon } from 'assets/plus-circle.svg';
import { ReactComponent as SearchIcon } from 'assets/search.svg';
import styles from './Contacts.module.scss';

import { IAutocompleteOption } from 'types';

export interface IContactGroup {
  contacts?: IAutocompleteOption[];
  createdBy?: string;
  createdOn?: Date;
  id?: string;
  modifiedBy?: string;
  title?: string;
}

export interface IContactAutocompleteOption extends IAutocompleteOption {
  email?: string;
  team?: string;
}

export interface IGroupFormProps {
  hideContacts?: boolean;
  item: IContactGroup;
  onClose: () => void;
  onNewContactClick?: () => void;
  onSubmit: (item: IContactGroup) => void;
  pendingSave?: boolean;
  readOnlyGroup?: boolean;
}

const GroupForm = ({
  hideContacts,
  item,
  onClose,
  onNewContactClick,
  onSubmit,
  pendingSave,
  readOnlyGroup,
}: IGroupFormProps) => {
  const { teamId, teamIds } = useMemoSelector((state) => ({
    teamId: getUserTeamId(state) || '',
    teamIds: getUserTeamIds(state),
  }));
  const methods = useForm<IContactGroup>({ defaultValues: item, mode: 'all' });
  const submitForm = async (values: IContactGroup) => {
    const result = pendingSave
      ? values
      : readOnlyGroup
      ? await Api.addContactsToGroup(values.id || '', values.contacts?.map((c) => c.id.toString()) || []).then(
          () => values
        )
      : await Api.addGroup(values, { errorPopupConfig: DEFAULT_ERROR_CONFIG });
    result && onSubmit(result);
  };
  const contacts = useWatch({ control: methods.control, name: 'contacts' });
  useDidUpdate(() => {
    methods.setValue('contacts', [...(contacts || []), ...(item.contacts || [])], {
      shouldTouch: true,
      shouldValidate: true,
    });
  }, [item?.contacts]);
  return (
    <Dialog classes={{ paper: styles.formPaper }} onClose={onClose} open>
      <DialogTitle className={styles.formTitle}>
        {readOnlyGroup ? (
          <>
            Add Contacts
            <div className={styles.formSecondaryTitle}>{item?.title || '-'}</div>
          </>
        ) : (
          'Create Group'
        )}
      </DialogTitle>
      <DialogContent className={styles.formContent}>
        {!readOnlyGroup && (
          <Controller
            control={methods.control}
            name="title"
            render={({ field, fieldState: { error }, formState }) => (
              <TextField
                {...field}
                className={styles.control}
                disabled={formState.isSubmitting}
                error={error?.message}
                highlightValid
                label="Title"
                required
              />
            )}
            rules={{
              validate: async (v) => {
                if (isEmpty(v, true)) return 'Required Field.';
                return await debouncePromise(
                  () =>
                    Api.getGroupByName(v as string, teamId, { errorPopupConfig: DEFAULT_ERROR_CONFIG }).then((group) =>
                      group && group.id !== item.id ? 'Title Taken.' : undefined
                    ),
                  'group-form-title'
                );
              },
            }}
          />
        )}
        {!hideContacts && (
          <>
            <Controller
              control={methods.control}
              name="contacts"
              render={({ field, fieldState: { error }, formState }) => (
                <Autocomplete
                  {...field}
                  className={styles.control}
                  disabled={formState.isSubmitting}
                  error={error?.message}
                  highlightValid
                  Icon={<SearchIcon height="13" width="13" />}
                  label="Select Contacts"
                  multiple
                  onResolveSuggestions={(text) =>
                    Api.getContacts(
                      { filter: { name: text, teamIds }, skip: 0, take: 10 },
                      { errorPopupConfig: DEFAULT_ERROR_CONFIG }
                    ).then((items) =>
                      items?.map(
                        (i): IContactAutocompleteOption => ({
                          email: i.email,
                          id: i.id || '',
                          team: i.team,
                          title: i.name || '',
                        })
                      )
                    )
                  }
                  renderOption={ContactOption}
                  required
                />
              )}
              rules={{ required: 'Required Field.' }}
            />
            <Button Icon={<PlusCircleIcon />} onClick={onNewContactClick} text="New Contact" />
          </>
        )}
      </DialogContent>
      <DialogActions className={styles.formActions}>
        <DoubleBtnGroup
          disabled={!methods.formState.isValid || !methods.formState.isDirty}
          isPending={methods.formState.isSubmitting}
          name1="Cancel"
          name2={readOnlyGroup ? 'Add Contacts' : item?.id ? 'Save' : 'Create'}
          onClick1={onClose}
          onClick2={methods.handleSubmit(submitForm)}
        />
      </DialogActions>
    </Dialog>
  );
};

export default GroupForm;
