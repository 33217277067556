import { ReactComponent as SpinnerIcon } from 'assets/pending.svg';
import styles from './Spinner.module.scss';

export interface ISpinnerProps {
  className?: string;
  height?: string | number;
  width?: string | number;
}

const Spinner = ({ className, height, width }: ISpinnerProps) => (
  <div className={className}>
    <SpinnerIcon className={styles.icon} height={height} width={width} />
  </div>
);

export default Spinner;
