import { KeyboardEvent, useCallback, useState } from 'react';
import { Chip, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';

import { ReactComponent as DeleteIcon } from 'assets/delete.svg';
import globalStyles from 'styles/modules/Global.module.scss';
import styles from './InputKeywords.module.scss';

export type KeywordsValueType = string[];

interface IInputKeywordsProps {
  label?: string;
  placeholder?: string;
  value: KeywordsValueType;
  onChange?: (data: KeywordsValueType) => void;
  labelPlacement?: 'start' | 'end' | 'top' | 'bottom';
  inputKeywordsClassName?: string;
  keywordChipClassName?: string;
  labelClassName?: string;
  validate?: (item: string) => boolean;
  maxLength?: number;
}

const InputKeywords = ({
  label,
  placeholder,
  labelPlacement = 'top',
  value,
  onChange,
  inputKeywordsClassName,
  labelClassName,
  keywordChipClassName,
  validate,
  maxLength = 25,
}: IInputKeywordsProps): JSX.Element => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault();
        const trimmedInputValue = inputValue.trim();

        if (!!trimmedInputValue && !value.includes(trimmedInputValue) && (!validate || validate(trimmedInputValue))) {
          onChange && onChange([...value, trimmedInputValue]);
          setInputValue('');
        }
      } else if (e.key === 'Backspace' && !inputValue) {
        onChange && onChange(value.slice(0, -1));
      }
    },
    [inputValue, onChange, validate, value]
  );

  const handleDelete = useCallback(
    (keyword) => {
      onChange && onChange(value.filter((item) => item !== keyword));
    },
    [onChange, value]
  );

  return (
    <FormControlLabel
      classes={{
        root: classNames(styles.formRoot),
        label: classNames(styles.label, labelClassName),
      }}
      label={label}
      labelPlacement={labelPlacement || 'start'}
      control={
        <div className={classNames(styles.inputKeywords, inputKeywordsClassName)}>
          {value.reduce(
            (total, keyword) =>
              [
                ...total,
                ...[
                  keyword ? (
                    <Chip
                      classes={{
                        root: classNames(styles.keywordChip, keywordChipClassName),
                        label: classNames(globalStyles.f11h13SuisseREG_grayDarker, styles.chipLabel),
                      }}
                      deleteIcon={<DeleteIcon />}
                      key={keyword}
                      label={keyword}
                      variant="outlined"
                      onDelete={() => handleDelete(keyword)}
                    />
                  ) : (
                    []
                  ),
                ],
              ] as JSX.Element[],
            [] as JSX.Element[]
          )}
          <input
            type="text"
            maxLength={maxLength}
            value={inputValue}
            placeholder={!value.length ? placeholder : ''}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </div>
      }
    />
  );
};

export default InputKeywords;
