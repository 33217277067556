import MuiDialog, { DialogProps as MuiDialogProps } from '@material-ui/core/Dialog';
import classNames from 'classnames';

import { useMemoSelector } from 'hooks';
import { getIsOpenSidebar } from 'store';

import sidebarStyles from 'styles/modules/Sidebar.module.scss';
import styles from './Dialog.module.scss';

export interface IDialogProps extends MuiDialogProps {
  closeFromBackdropClick?: boolean;
  playbarVisible?: boolean;
}

const Dialog = (props: IDialogProps) => {
  const isOpenSidebar = useMemoSelector(getIsOpenSidebar);
  const muiDialogProps = { ...props };
  delete muiDialogProps.closeFromBackdropClick;
  delete muiDialogProps.playbarVisible;

  const onClose = (e: Record<string, never>, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (props.closeFromBackdropClick && props.onClose) {
      props.onClose(e, reason);
    }
    reason !== 'backdropClick' && props.onClose && props.onClose(e, reason);
  };

  return (
    <MuiDialog
      {...muiDialogProps}
      BackdropProps={{ invisible: true, ...props.BackdropProps }}
      className={classNames(props.className, { [styles.playbarRelatedLayer]: props.playbarVisible })}
      onClose={onClose}
      PaperProps={{
        square: true,
        ...props.PaperProps,
        classes: {
          ...props.PaperProps?.classes,
          root: classNames(styles.paperRoot, props.PaperProps?.classes?.root, {
            [sidebarStyles.halfMarginTransform]: isOpenSidebar,
            [styles.playbarRelatedMargin]: props.playbarVisible,
          }),
        },
      }}
    />
  );
};

export default Dialog;
