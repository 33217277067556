import { Checkbox as CheckboxMaterial, FormControlLabel } from '@material-ui/core';
import classNames from 'classnames';

import { ReactComponent as CheckedIcon } from 'assets/checked.svg';
import { ReactComponent as UncheckedIcon } from 'assets/unchecked.svg';
import styles from './Checkbox.module.scss';

interface ICheckboxProps {
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (checked: boolean) => void;
  checkboxRootClassName?: string;
  labelClassName?: string;
  labelDisabledClassName?: string;
  colorSecondaryClassName?: string;
  checkboxDisabledClassName?: string;
  fromControlRootClassName?: string;
}

const Checkbox = ({
  checked,
  disabled,
  onChange,
  label,
  checkboxRootClassName,
  colorSecondaryClassName,
  checkboxDisabledClassName,
  labelClassName,
  labelDisabledClassName,
  fromControlRootClassName,
}: ICheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <CheckboxMaterial
          classes={{
            root: classNames(styles.checkboxRoot, checkboxRootClassName),
            colorSecondary: classNames(styles.colorSecondary, colorSecondaryClassName),
            disabled: classNames(styles.checkboxDisabled, checkboxDisabledClassName),
          }}
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          disableRipple
          checked={checked}
          disabled={disabled}
          onChange={(_, value) => onChange && onChange(value)}
        />
      }
      label={label}
      classes={{
        root: classNames(styles.formControl, fromControlRootClassName),
        label: classNames(styles.label, labelClassName),
        disabled: classNames(styles.controlDisabled, labelDisabledClassName),
      }}
    />
  );
};

export default Checkbox;
